import React from 'react';
import './Formfields.scss';

interface IInputTextProps {
    id: string;
    value: string;
    name: string;
    label: string;
    index: number;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    error?: string;
    handleFormFieldChange: (index: number, value: any) => void;
}

function InputText(props: IInputTextProps) {
    let errorClass = '';
    let errorMessage = '';
    if (props.error !== undefined && props.error.length > 0) {
        errorClass = 'input-label-wrapper--error';
        errorMessage = ' - ' + props.error;
    }

    return (
        <div className={`input-label-wrapper ${errorClass}`}>
            <label htmlFor={props.id}>
                {props.label}
                {props.required ? ' *' : ''} {errorMessage}
            </label>
            <input
                type={'text'}
                name={props.name}
                id={props.id}
                required={props.required}
                placeholder={props.placeholder}
                disabled={props.disabled}
                value={props.value}
                onChange={event => {
                    props.handleFormFieldChange(props.index, { type: 'text', value: event.target.value });
                }}
            />
        </div>
    );
}

export default InputText;
