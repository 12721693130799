import { useEffect, useRef } from 'react';

function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = JSON.parse(JSON.stringify(value));
    }, [value]);
    return ref.current;
}

export default usePrevious;
