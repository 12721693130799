import React from 'react';
import {EPackage, IProcessedConfigurationOption} from '../../types/types';
import SubOptionColorSwatch from './SubOptionColorSwatch';
import './SubOptions.scss';
import SubOptionTextOnly from './SubOptionTextOnly';
import { motion } from 'framer-motion';
import { FRAMER_MOTION_VARIANT_HEIGHT } from '../../utilities/framerMotionVariants';
import {useConfigurationStore} from "../../store/store";

interface ISubOptionsProps {
    subOptions: IProcessedConfigurationOption[] | undefined; 
    subOptionsToggle: boolean;
    optionGroupIndex: number;
    optionIndex: number;
}

function renderSubOptions(subOptions: IProcessedConfigurationOption[], optionGroupIndex: number, optionIndex: number, subOptionsToggle: boolean, basicEquipmentPackage: EPackage) {
    return subOptions.map((item, subOptionIndex) => {
        const shouldRender = item.availableInPackages.includes(basicEquipmentPackage);
        if(!shouldRender) return;
        
        switch (item.optionType) {
            case 'sub_colorSwatch': {
                return <SubOptionColorSwatch subOption={item} key={subOptionIndex} optionGroupIndex={optionGroupIndex} optionIndex={optionIndex} subOptionIndex={subOptionIndex} />;
            }
            case 'sub_textOnly': {
                return <SubOptionTextOnly subOption={item} key={subOptionIndex} optionGroupIndex={optionGroupIndex} optionIndex={optionIndex} subOptionIndex={subOptionIndex} toggle={subOptionsToggle} />;
            }
        }
    });
}
function SubOptions(props: ISubOptionsProps) {
    const basicEquipmentPackage = useConfigurationStore(state => state.basicEquipmentPackage);
    
    if (!props.subOptions) return null;

    let modifier = '';
    if (props.subOptions[0].optionType === 'sub_colorSwatch') {
        modifier = 'color-swatches';
    }
    if (props.subOptions[0].optionType === 'sub_textOnly') {
        modifier = 'text-only';
    }

    return (
        <motion.div key={'groupIndex' + props.optionGroupIndex + 'optionIndex' + props.optionIndex} variants={FRAMER_MOTION_VARIANT_HEIGHT} initial={'initial'} animate={'animate'} exit={'exit'} className={`sub-options`}>
            <ul className={`sub-options__inner ${modifier.length > 0 ? 'sub-options__inner--' + modifier : ''}`}>{renderSubOptions(props.subOptions, props.optionGroupIndex, props.optionIndex, props.subOptionsToggle, basicEquipmentPackage)}</ul>
        </motion.div>
    );
}

export default SubOptions;
