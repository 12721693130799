import React from 'react';
import './HTMLTester.scss';

interface IHtmlTesterProps {
    html: string;
}

function HtmlTester(props: IHtmlTesterProps) {
    return <div className={'html-tester'} dangerouslySetInnerHTML={{ __html: props.html }}></div>;
}

export default HtmlTester;
