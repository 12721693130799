import {
    ELanguage,
    IConfigurationCategory,
    IProcessedConfigurationCategory,
    IProcessedConfigurationOption,
    IProcessedOptionGroup,
    IProcessedSceneActionHotspot,
    ISceneActionHotspot,
    isIProcessedConfigurationCategory,
    isIProcessedOptionGroup,
    TActiveOverride
} from "../types/types";
import {
    IFormFieldCheckbox,
    IFormFieldCheckboxProcessed,
    IFormFieldGeneric,
    IFormFieldGenericProcessed,
    IFormFieldRadioGroup,
    IFormFieldRadioGroupProcessed,
    IFormFieldSelect,
    IFormFieldSelectChoiceProcessed,
    IFormFieldSelectProcessed,
    isFormFieldCheckbox,
    isFormFieldGeneric,
    isFormFieldRadioGroup,
    isFormFieldSelect,
} from "../types/formTypes";
import DATA from "../data/data.json";

/**
 * Custom URLSearchParams creator.
 * Creates an object by translating the query-string present in the URL into an object with respective key-value pairs.
 * Is needed because the native URLSearchParams-API parses the query string in unexpected ways.
 * Using encodeURIComponent and decodeURIComponent leads to different (=correct) results than "new URLSearchParams" and
 * the respective .toString()-method.
 */
export class CustomUrlParams {
    private qs: string;
    public params: { [key: string]: string } = {};

    constructor(search: string) {
        this.qs = (search || window.location.search).substr(1);
        this.parseQuerstring();
    }

    parseQuerstring() {
        this.qs.split("&").reduce((a, b) => {
            let [key, val] = b.split("=");
            //@ts-ignore
            a[key] = val;
            return a;
        }, this.params);

        for (const [key, value] of Object.entries(this.params)) {
            if (key === "") {
                delete this.params[""];
            }
        }
    }

    returnQueryString() {
        let newQueryString = "?";
        for (const [key, value] of Object.entries(this.params)) {
            newQueryString += key + "=" + value + "&";
        }
        newQueryString = newQueryString.slice(0, -1);
        return newQueryString;
    }

    get(key: string) {
        return this.params[key];
    }
}

/**
 * Creates a queryString from the currently active options (ie. all active options in the configurationStore)
 * @returns {string} - i.e. "model,modelOptionGroup1,rc;look,lookOptionGroup1,allWhite"
 */
export function createQueryString(
    inputArray: IProcessedConfigurationCategory[] | IProcessedOptionGroup[],
    queryString: string = "",
    parentNames: string[] = []
) {
    inputArray.map((inputArrayItem, inputArrayItemIndex) => {
        if (isIProcessedConfigurationCategory(inputArrayItem)) {
            let newParentNames = [];
            newParentNames.push(inputArrayItem.name);
            queryString = createQueryString(
                inputArrayItem.optionGroups,
                queryString,
                newParentNames
            );
        }
        else if (isIProcessedOptionGroup(inputArrayItem)) {
            let newParentNames = parentNames.map((x) => x);
            newParentNames.push(inputArrayItem.name);
            inputArrayItem.options.forEach((option, optionIndex) => {
                if (option.active) {
                    newParentNames.map((name) => {
                        if (
                            queryString.length === 0 ||
                            queryString[queryString.length - 1] === ";"
                        ) {
                            queryString = queryString + name;
                        }
                        //
                        else {
                            queryString = queryString + "," + name;
                        }
                    });
                    queryString = queryString + "," + option.name + ";";

                    if (option.subOptions) {
                        option.subOptions.map((subOption) => {
                            if (subOption.active) {
                                newParentNames.map((name) => {
                                    if (
                                        queryString.length === 0 ||
                                        queryString[queryString.length - 1] === ";"
                                    ) {
                                        queryString = queryString + name;
                                    }
                                    //
                                    else {
                                        queryString = queryString + "," + name;
                                    }
                                });

                                queryString =
                                    queryString + "," + option.name + "," + subOption.name + ";";
                            }
                        });
                    }
                }
            });
        }
    });
    
    return queryString;
}

/**
 * Transforms an uriEncoded options-queryString to an array,
 * i.e. "exterior%2Chull%2Cpainted%2CaristoBlue%3Bexterior%2Cwaterpass%2Cgelcoat%2CsignalWhite%3B" becomes
 * [["model","modelOptionGroup1","rc"],["bimini","placementOptionGroup","frontRoof"]]
 */
export function transformQueryString(
    encodedQueryString: string
): TActiveOverride[] {
    try {
        //Remove last semicolon if present
        let decodedQueryString = decodeURIComponent(encodedQueryString);
        if (decodedQueryString[decodedQueryString.length - 1] === ";") {
            decodedQueryString = decodedQueryString.slice(0, -1);
        }

        //Separate at ";"-delimiter
        const separatedOverrideStrings: string[] = decodedQueryString.split(";");

        //Separate at ","-delimiter
        for (let i = 0; i < separatedOverrideStrings.length; i++) {
            //@ts-ignore
            separatedOverrideStrings[i] = separatedOverrideStrings[i].split(
                ","
            ) as TActiveOverride;
        }

        return separatedOverrideStrings as unknown as TActiveOverride[];
    } catch {
        //
        //
        //
        return [];
    }
}

/**
 * Goes through entire configuration and sets "active: true" in all those that are defined in activeOverrides. Also
 * sets respective other options on the same layer to "active: false" if toggle isn't turned on in the optionGroup
 */
export function applyActiveOverrides(configuration: IProcessedConfigurationCategory[],activeOverrides: TActiveOverride[] | null): IProcessedConfigurationCategory[] {
    if (!activeOverrides) return configuration;
    let currentOptions = structuredClone(
        configuration
    ) as IProcessedConfigurationCategory[];
    try {
        for (let a = 0; a < activeOverrides.length; a++) {
            let arrayOfNames = activeOverrides[a];

            let selectedConfigurationCategory = currentOptions.find(
                (element: IProcessedConfigurationCategory) => {
                    return element.name === arrayOfNames[0];
                }
            ); //ie. "model"
            if (!selectedConfigurationCategory)
                throw new Error("selectedConfigurationCategory not found");
            let selectedOptionGroup = selectedConfigurationCategory.optionGroups.find(
                (element: IProcessedOptionGroup) => {
                    return element.name === arrayOfNames[1];
                }
            ); //ie. "modelOptionGroup1"
            if (!selectedOptionGroup)
                throw new Error("selectedOptionGroup not found");
            let selectedOption = selectedOptionGroup.options.find(
                (element: IProcessedConfigurationOption) => {
                    return element.name === arrayOfNames[2];
                }
            ); //i.e. "rc"
            if (!selectedOption) throw new Error("selectedOption not found");
            if (!selectedOptionGroup.toggle) {
                selectedOptionGroup.options.map(
                    (option: IProcessedConfigurationOption) => {
                        option.active = false;
                    }
                );
            }
            selectedOption.active = true;
            if (activeOverrides[a].length === 4) {
                let selectedSubOption = selectedOption.subOptions?.find(
                    (element: IProcessedConfigurationOption) => {
                        return element.name === arrayOfNames[3];
                    }
                );
                if (!selectedSubOption) throw new Error("selectedSubOption not found");
                if (selectedOption.subOptions) {
                    selectedOption.subOptions.map((subOption) => {
                        subOption.active = false;
                    });
                    selectedSubOption.active = true;
                }
            }
        }
    } catch {
        const UrlParams = new CustomUrlParams(window.location.search);
        //@ts-ignore
        delete UrlParams.params[DATA.saveConfiguration.queryParamNameConfiguration];

        window.history.replaceState(
            null,
            "",
            window.location.pathname + UrlParams.returnQueryString()
        );
    }
    return currentOptions;
}

/**
 * Adds the active property to all options in the configuration.
 * @param data
 */
export function processConfigurationCategories(data: IConfigurationCategory[]): IProcessedConfigurationCategory[] {
    return data.map((category) => {
        return {
            ...category,
            optionGroups: category.optionGroups.map(
                (optionGroup, optionGroupIndex) => {
                    return {
                        ...optionGroup,
                        options: optionGroup.options.map((option) => {
                            (option as IProcessedConfigurationOption)["displayedPrice"] = 0;
                            if (option.active === undefined) {
                                option["active"] = false;
                            }
                            if (option.subOptions) {
                                option.subOptions = option.subOptions.map((subOption) => {
                                    (subOption as IProcessedConfigurationOption)["displayedPrice"] = 0;
                                    if (subOption.active === undefined) {
                                        subOption["active"] = false;
                                    }
                                    return subOption as IProcessedConfigurationOption;
                                });
                            }
                            return option as IProcessedConfigurationOption;
                        }),
                    };
                }
            ),
        };
    });
}

/**
 * Adds the active property to all options in the configuration.
 * @param data
 */
export function processSceneActionHotspots(
    data: ISceneActionHotspot[]
): IProcessedSceneActionHotspot[] {
    return data.map((hotspot) => {
        const tempObject = {...hotspot};

        if (hotspot.active === undefined) {
            tempObject["active"] = false;
        }
        if (hotspot.animationState === undefined) {
            tempObject["animationState"] = "isStopped";
        }

        return tempObject as IProcessedSceneActionHotspot;
    });
}

/**
 * Adds properties to incoming formfield data so that the entire formField-array can be used to keep state of
 * all formfield-values and selections in FormOverlay.
 * Formfields of type "text","textarea","email" get the property "value" if not already present
 * Formfields of type "select" get property "selected=false" to all options if not already present
 * Formfields of type "radioGroup" get property "checked=false" to all choices if not already present
 * Formfields of type "checkbox" get property "checked=false" if not already present
 *
 * Also checks for the value of "de" in select-options. If it finds it, it assumes that the select is a
 * country-select and sets the option that has the value of parameter "language" as selected
 *
 * @param formFields - array of formfield-objects
 * @param language - language string
 * @returns array - basically the same array as input array but with described added properties
 */
export function prepareFormFieldData(
    formFields: (
        | IFormFieldGeneric
        | IFormFieldSelect
        | IFormFieldRadioGroup
        | IFormFieldCheckbox
        )[],
    language: ELanguage
) {
    return formFields.map((formField) => {
        if (isFormFieldGeneric(formField)) {
            if (formField.value === undefined) {
                formField["value"] = "";
            }

            return formField as IFormFieldGenericProcessed;
        }
        //
        else if (isFormFieldSelect(formField)) {
            let isProbablyACountrySelect = false;
            formField.choices = formField.choices.map((choice) => {
                if (choice.value.toUpperCase() === "DE") {
                    isProbablyACountrySelect = true;
                }
                if (choice.selected === undefined) {
                    choice["selected"] = false;
                }
                return choice as IFormFieldSelectChoiceProcessed;
            });

            if (isProbablyACountrySelect) {
                for (let i = 0; i < formField.choices.length; i++) {
                    if (
                        formField.choices[i].value.toUpperCase() === language.toUpperCase()
                    ) {
                        formField.choices[i].selected = true;
                        break;
                    }
                }
            }

            return formField as IFormFieldSelectProcessed;
        }
        //
        else if (isFormFieldRadioGroup(formField)) {
            formField.choices = formField.choices.map((choice) => {
                if (choice.checked === undefined) {
                    choice["checked"] = false;
                }
                return choice;
            });

            return formField as IFormFieldRadioGroupProcessed;
        }
        //
        else if (isFormFieldCheckbox(formField)) {
            if (formField.checked === undefined) {
                formField.checked = false;
            }

            return formField as IFormFieldCheckboxProcessed;
        }
        //
        else return formField;
    });
}
