import React from 'react';
import './TextOnly.scss';
import {ImCheckmark} from 'react-icons/im';
import {ECurrencies, EPackage, IProcessedConfigurationOption} from '../../types/types';
import SubOptions from '../SubOptions/SubOptions';
import {AnimatePresence} from 'framer-motion';
import {useConfigurationStore, useGlobalStore} from '../../store/store';
import PackageIcon from "../PackageIcon/PackageIcon";
import DATA from "../../data/data.json";
import {formatPrice} from "../../utilities/utilities";

interface ITextOnly {
    titleHtml?: string;
    descriptionHtml?: string;
    active?: boolean;
    mandatory?: boolean;
    onClick?: () => void;
    toggle?: boolean;
    subOptions?: IProcessedConfigurationOption[] | undefined;
    subOptionsToggle: boolean;
    optionGroupIndex: number;
    optionIndex: number;
    displayedPrice: number;
    availableInPackages: string[];
}
function TextOnly(props: ITextOnly) {
    const currentLanguage = useGlobalStore(state => state.currentLanguage);
    const basicEquipmentPackage = useConfigurationStore(state => state.basicEquipmentPackage);
    const isPackageDisabled = !props.availableInPackages.includes(basicEquipmentPackage);
    
    let formattedPrice = "";
    if((!isPackageDisabled && !props.active)) {
        formattedPrice = formatPrice(props.displayedPrice, true, true);
    }

    return (
        <div className={`text-only ${props.descriptionHtml ? 'text-only--has-description' : ''} ${props.toggle ? 'text-only--toggle' : ''} ${props.active ? 'text-only--active' : ''} ${isPackageDisabled ? 'text-only--package-disabled' : ''}`}>
            <button onClick={props.onClick} className={`text-only__inner`}>
                <div className={`text-only__text-container`}>
                    {props.titleHtml && <h2 className={'text-only__title'} dangerouslySetInnerHTML={{ __html: `${props.titleHtml}${props.mandatory ? " *" : ""}${formattedPrice ? '<span>' + formattedPrice + '</span>': ''}` }} />}
                    {props.descriptionHtml && <p className={'text-only__description'} dangerouslySetInnerHTML={{ __html: props.descriptionHtml }} />}
                    {isPackageDisabled && <div className={'text-only__package-hint'}><PackageIcon type={EPackage.SUPERSPORT}/><span dangerouslySetInnerHTML={{ __html: DATA.locale.onlyAvailableWithSupersport[currentLanguage] }}/></div>}
                </div>
                {props.toggle && (
                    <div className={`text-only__icon-container`}>
                        <div className={`text-only__icon`}>{props.active && <ImCheckmark />}</div>
                    </div>
                )}
            </button>
            <AnimatePresence>{props.active ? <SubOptions subOptions={props.subOptions} optionGroupIndex={props.optionGroupIndex} optionIndex={props.optionIndex} subOptionsToggle={props.subOptionsToggle} /> : null}</AnimatePresence>
        </div>
    );
}
export default TextOnly;
