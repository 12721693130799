import React from 'react';
import './Formfields.scss';

interface ISelectProps {
    id: string;
    name: string;
    label: string;
    index: number;
    required?: boolean;
    disabled?: boolean;
    handleFormFieldChange: (index: number, value: any) => void;
    options: ISelectOption[];
}

export interface ISelectOption {
    value: string;
    label: string;
    selected?: boolean;
}

function Select(props: ISelectProps) {
    let selectedValue = props.options[0].value;
    for (let i = 0; i < props.options.length; i++) {
        if (props.options[i].selected) {
            selectedValue = props.options[i].value;
            break;
        }
    }
    return (
        <div className='input-label-wrapper'>
            <label htmlFor={props.id}>
                {props.label}
                {props.required ? ' *' : ''}
            </label>
            <select
                name={props.name}
                id={props.id}
                value={selectedValue}
                required={props.required}
                onChange={event => {
                    props.handleFormFieldChange(props.index, { type: 'select', selectedValue: event.target.value });
                }}
            >
                {props.options.map((option, optionIndex) => {
                    return (
                        <option key={optionIndex} value={option.value}>
                            {option.label}
                        </option>
                    );
                })}
            </select>
        </div>
    );
}

export default Select;
