import { ReactNode } from "react";
import "./Accordeon.scss";
import { useState } from "react";
import { motion } from "framer-motion";
import { IconContext } from 'react-icons';
import { FiPlus } from 'react-icons/fi';

interface IAccordeonProps {
    titleHtml: string;
    children: ReactNode;
}


function Accordeon(props: IAccordeonProps) {
    const [isOpen, setIsOpen] = useState(false);
    
    return (
        <motion.div className={'accordeon'}>
            <motion.div className={'accordeon__inner'} initial={{paddingBottom: 0}} animate={isOpen ? {paddingBottom: getComputedStyle(document.documentElement).getPropertyValue('--space-l')} : {paddingBottom: 0}}>
                <div className={'accordeon__title'} onClick={() => {setIsOpen(!isOpen)}}>
                    <h1>{props.titleHtml}</h1>
                    <IconContext.Provider value={{size: getComputedStyle(document.documentElement).getPropertyValue('--space-l')}}>
                        <motion.div animate={{rotate: isOpen ? 45 : 0}} transition={{duration: 0.3}}>
                            <FiPlus/>
                        </motion.div>
                    </IconContext.Provider>
                </div>
                <motion.div className={`accordeon__content`} initial={{height: 0}} animate={isOpen ? {height: "auto"} : {height: 0}}>
                    {props.children}
                </motion.div>
            </motion.div>
        </motion.div>
    );
}

export default Accordeon;