import React from 'react';
import './Formfields.scss';

interface IInputTextareProps {
    name: string;
    id: string;
    value: string;
    label: string;
    index: number;
    required?: boolean;
    disabled?: boolean;
    handleFormFieldChange: (index: number, value: any) => void;
}

function InputTextarea(props: IInputTextareProps) {
    return (
        <div className='input-label-wrapper'>
            <label htmlFor={props.id}>{props.label}</label>
            <textarea
                name={props.name}
                id={props.id}
                required={props.required}
                disabled={props.disabled}
                value={props.value}
                onChange={event => {
                    props.handleFormFieldChange(props.index, { type: 'textarea', value: event.target.value });
                }}
            />
        </div>
    );
}

export default InputTextarea;
