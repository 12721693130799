import React, { ReactElement } from 'react';
import { motion } from 'framer-motion';
import './Lightbox.scss';
import { MdClose } from 'react-icons/md';
import { IconContext } from 'react-icons';
import { FRAMER_MOTION_VARIANT_LIGHTBOX } from '../../utilities/framerMotionVariants';

interface ILightboxProps {
    handleCloseButtonClick: () => void;
    title?: string;
    children?: ReactElement;
}

const Lightbox = (props: ILightboxProps) => {
    return (
        <motion.div
            className='lightbox'
            variants={{
                initial: {
                    opacity: 0,
                },
                animate: {
                    opacity: 1,
                },
                exit: {
                    opacity: 0,
                    transition: {
                        when: 'afterChildren',
                    },
                },
            }}
            initial='initial'
            animate='animate'
            exit='exit'
        >
            <motion.div variants={FRAMER_MOTION_VARIANT_LIGHTBOX} className='lightbox__inner'>
                {!props.title && (
                    <button className='lightbox__closer' onClick={props.handleCloseButtonClick}>
                        <IconContext.Provider value={{size: getComputedStyle(document.documentElement).getPropertyValue('--space-l')}}>
                            <MdClose/>
                        </IconContext.Provider>
                    </button>
                )}
                {props.title && (
                    <div className='lightbox__title'>
                        <h1>
                            <span dangerouslySetInnerHTML={{__html: props.title}}/>
                            <button className='lightbox__closer' onClick={props.handleCloseButtonClick}>
                                <IconContext.Provider value={{size: getComputedStyle(document.documentElement).getPropertyValue('--space-l')}}>
                                    <MdClose/>
                                </IconContext.Provider>
                            </button>
                        </h1>
                    </div>
                )
                }
                <div className='lightbox__innermost'>{props.children}</div>
            </motion.div>
        </motion.div>
    );
};

export default Lightbox;
