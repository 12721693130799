import './PackageIcon.scss';
import {EPackage} from "../../types/types";

interface IPackageIconProps {
    type: EPackage
}

function PackageIcon(props: IPackageIconProps) {
    switch (props.type) {
        case EPackage.SPORT: {
            return null
        }
        case EPackage.SUPERSPORT: {
            return (
                <svg className={'package-svg-icon'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                    <path className="fill-color-red" d="M212.941,589.283l66.185-391.709h519.517l-19.88,117.735h-329.117c-97.613,0-151.39,39.958-151.39,112.49,0,35.969,19.373,78.88,111.586,78.88h127.962c61.666,0,79.474,6.965,79.474,31.098,0,34.183-25.301,51.506-75.199,51.506H212.941ZM438.273,373.472c-49.898,0-75.199,17.169-75.199,51.022,0,22.767,18.557,29.71,79.474,29.71h128.447c91.42,0,110.617,42.36,110.617,77.888,0,76.235-50.757,114.892-150.905,114.892H208.863c1.895,46.504,17.125,88.445,44.939,121.394,37.049,43.815,93.426,67.948,158.707,67.948,153.417,0,299.408-125.383,325.436-279.484l30.987-183.369h-330.659Z"/>
                    <path className="fill-color-black" d="M412.531,996.436c-113.239,0-213.034-44.278-281.005-124.722-69.16-81.833-96.555-190.202-77.117-305.182L143.78,37.45h844.314l-92.258,546.052c-38.481,227.713-255.285,412.933-483.306,412.933M229.911,139.339l-75.045,444.164c-14.348,84.918,5.003,163.909,54.482,222.445,48.289,57.127,120.447,88.6,203.183,88.6,180.526,0,352.258-147.158,382.827-328.015l72.18-427.194H229.911Z"/>
                </svg>
            )
        }
    }
}

export default PackageIcon;
