import { useGlobalStore } from '../../store/store';
import './SaveButton.scss';
import DATA from '../../data/data.json';

interface ISaveButtonProps {}
function SaveButton() {
    const currentLanguage = useGlobalStore(state => state.currentLanguage);
    const viewport = useGlobalStore(state => state.viewport);

    return (
        <div className={'save-button'}>
            <div
                onClick={() => {
                    useGlobalStore.setState({ saveConfigurationOverlayIsOpen: true });
                }}
                key={'save'}
                className={`save-button__item`}
            >
                {viewport.width < 1450 ? DATA.locale.saveConfigurationMobile[currentLanguage] : DATA.locale.saveConfiguration[currentLanguage]}
            </div>
        </div>
    );
}

export default SaveButton;
