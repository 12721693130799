import './SpinnerJimu.scss';

function SpinnerJimu() {
    return (
        <div className='spinner-jimu'>
            <div className='spinner-jimu__inner'></div>
        </div>
    );
}

export default SpinnerJimu;
