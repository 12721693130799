import React from 'react';
import './NavigationInfo.scss';
import { STEPS_BEFORE_CONFIGURATION } from '../../../utilities/globals';
import { motion } from 'framer-motion';
import { useGlobalStore, useThreeDReference } from '../../../store/store';
import { MdFullscreen } from 'react-icons/md';
import { GiCrosshair } from 'react-icons/gi';
import { RxCrosshair2 } from 'react-icons/rx';
import DATA from '../../../data/data.json';

interface INavigationInfoProps {}
function NavigationInfo(props: INavigationInfoProps) {
    const currentStepIndex = useGlobalStore(state => state.currentStepIndex);
    const fullscreenIsActive = useGlobalStore(state => state.fullscreenIsActive);
    const currentLanguage = useGlobalStore(state => state.currentLanguage);

    const styles: string = `
        .three-sixty-cls-1 { 
            fill: none;
            stroke: var(--color-font-primary-soft-dynamic);
            transition: stroke 800ms;
        }

        .three-sixty-cls-1, .three-sixty-cls-2, .three-sixty-cls-3 {
            stroke - miterlimit: 10;
            stroke-width: 2px;
        }

        .three-sixty-cls-2 {
            stroke: url(#Unbenannter_Verlauf_5);
        }

        .three-sixty-cls-2, .three-sixty-cls-3 {
            fill: none;
        }

        .three-sixty-cls-3 {
            stroke: url(#Unbenannter_Verlauf_2); 
        }
    `;

    return (
        <motion.div className={'navigation-info'} animate={{ opacity: currentStepIndex > STEPS_BEFORE_CONFIGURATION - 1 ? 1 : 0 }}>
            <div className={'navigation-info__inner'}>
                <div className={'navigation-info__innermost'}>
                    <span className={'navigation-info__number'}>360°</span>
                    <svg viewBox='0 0 1305.5609 128.6523'>
                        <defs>
                            <style>{styles}</style>
                            <linearGradient id='Unbenannter_Verlauf_2' data-name='Unbenannter Verlauf 2' x1='0' y1='61.3716' x2='586.1587' y2='61.3716' gradientUnits='userSpaceOnUse'>
                                <stop offset='0' stopColor='var(--color-font-primary-dynamic)' stopOpacity='0' />
                                <stop offset='1' stopColor='var(--color-font-primary-dynamic)' />
                            </linearGradient>
                            <linearGradient id='Unbenannter_Verlauf_5' data-name='Unbenannter Verlauf 5' x1='719.4021' y1='61.3716' x2='1305.5609' y2='61.3716' gradientUnits='userSpaceOnUse'>
                                <stop offset='0' stopColor='var(--color-font-primary-dynamic)' />
                                <stop offset='1' stopColor='var(--color-font-primary-dynamic)' stopOpacity='0' />
                            </linearGradient>
                        </defs>
                        <path className='three-sixty-cls-3' d='m586.1397,121.7433c-21.9109-.4178-43.5011-1.0393-64.7157-1.8544-21.2147-.8151-42.0539-1.8237-62.4631-3.0155s-40.3883-2.567-59.8828-4.1151-38.5043-3.2693-56.9749-5.1532c-18.4706-1.8839-36.4019-3.9306-53.7394-6.1297s-34.0811-4.5508-50.1762-7.0448c-16.0952-2.4939-31.5419-5.1301-46.2855-7.8983s-28.7842-5.6684-42.0671-8.6903-25.8081-6.1656-37.5211-9.4208-22.6137-6.6219-32.6475-10.0898-19.2008-7.0371-27.4462-10.6973-15.5695-7.4114-21.9174-11.2433-11.7197-7.7446-16.0609-11.7278-7.6516-8.0368-9.8768-12.1507S1,4.2241,1,0' />
                        <path className='three-sixty-cls-2' d='m1304.5609,0c0,4.2241-1.1399,8.3983-3.3651,12.5121s-5.5356,8.1675-9.8768,12.1507-9.713,7.8958-16.0609,11.7278-13.6719,7.5831-21.9174,11.2433-17.4124,7.2294-27.4462,10.6973-20.9345,6.8346-32.6475,10.0898-24.2382,6.3989-37.5211,9.4208-27.3235,5.9221-42.0671,8.6903-30.1903,5.4044-46.2855,7.8983c-16.0952,2.4939-32.8388,4.8456-50.1762,7.0448s-35.2688,4.2458-53.7394,6.1297c-18.4706,1.8839-37.4804,3.605-56.9749,5.1532s-39.4736,2.9232-59.8828,4.1151-41.2484,2.2004-62.4631,3.0155c-21.2147.8151-42.8048,1.4366-64.7157,1.8544' />
                        <polyline className='three-sixty-cls-1' points='608.1229 127.9452 602.2203 122.0426 608.1229 116.14' />
                        <polyline className='three-sixty-cls-1' points='697.7269 116.14 703.6295 122.0426 697.7269 127.9452' />
                    </svg>
                </div>
                <div className={'navigation-info__menu'}>
                    <div
                        className={'navigation-info__menu-item navigation-info__menu-item--clickable'}
                        onClick={() => {
                            useThreeDReference.getState().threeD?.centerCamera();
                        }}
                    >
                        <RxCrosshair2 />
                        {DATA.locale.resetCamera[currentLanguage]}
                    </div>
                    <div className={'navigation-info__disclaimer'}>
                        <span>{DATA.locale.rotateCamera[currentLanguage]}</span>
                        <span>{DATA.locale.zoomExplanation[currentLanguage]}</span>
                        <span>{DATA.locale.disclaimer[currentLanguage]}</span>
                    </div>
                    <div
                        className={'navigation-info__menu-item navigation-info__menu-item--clickable'}
                        onClick={() => {
                            if (fullscreenIsActive) {
                                document.exitFullscreen();
                            } else {
                                document.documentElement.requestFullscreen();
                            }
                        }}
                    >
                        <MdFullscreen />
                        {fullscreenIsActive ? DATA.locale.exitFullscreen[currentLanguage] : DATA.locale.enterFullscreen[currentLanguage]}
                    </div>
                </div>
            </div>
        </motion.div>
    );
}
export default NavigationInfo;
