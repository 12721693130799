import './LightboxContentSummary.scss';
import DATA from '../../data/data.json';
import {useConfigurationStore, useGlobalStore} from '../../store/store';
import {ECurrencies, ELanguage, EPackage, IProcessedConfigurationCategory, Prices} from "../../types/types";
import {calculatePriceFromConfiguration, checkCondition, checkIfCategoryHasActiveOptions, formatPrice} from "../../utilities/utilities";
import PackageIcon from "../PackageIcon/PackageIcon";

interface ILightboxContentSummaryProps {}

/**
 * Returns a JSX element with the special model and engine group
 * This is needed because the model and engine options are separate in the data.json, but need to be displayed together in the summary, because
 * we're not allowed to show the isolated engine price. This is complicated even more by the fact that there is mandatory additional options for the engine
 */
export const returnSpecialModelEngineGroup = (mode: "jsx" | "html", configuration: IProcessedConfigurationCategory[], currentLanguage: ELanguage, currentCurrency: ECurrencies, currentPackage: EPackage) => {
    let html = '<p>&nbsp;</p><table style="border: 1px solid black; padding: 15px;" width="100%">';
    
    html += `<tr><td><h2>Sterk ${currentPackage.charAt(0).toUpperCase() + currentPackage.slice(1)}</h2></td><td></td></tr>`;
    html += `<tr><td>&nbsp;</td><td>&nbsp;</td></tr>`;
    
    const returnElements: JSX.Element[] = [];
    const listReturnElements: JSX.Element[] = [];
    const modelCategory = configuration.find(category => category.name === "model");
    const engineCategory = configuration.find(category => category.name === "engines");
    
    if(!modelCategory || !engineCategory) return;
    
    // "Model + Engine"
    returnElements.push(<h2>{modelCategory.displayName[currentLanguage]} + {engineCategory.displayName[currentLanguage]}</h2>)
    html += '<td><b>' + modelCategory.displayName[currentLanguage] + ' + ' + engineCategory.displayName[currentLanguage] + ':</b></td><td></td></tr>';
    modelCategory.optionGroups.forEach((modelOptionGroup, modelIndex) => {
        modelOptionGroup.options.forEach((modelOption, modelOptionIndex) => {
            if(modelOption.active) {
                engineCategory.optionGroups.forEach((engineOptionGroup, engineOptionGroupIndex) => {
                    
                    //First entry: Model + Engine
                    if(engineOptionGroupIndex === 0) {
                        engineOptionGroup.options.forEach((engineOption, engineOptionIndex) => {
                            if (engineOption.active) {
                                //@ts-ignore
                                const packageDependentPricesArrayModel = modelOption.prices[currentPackage] ? modelOption.prices[currentPackage] : modelOption.prices["*"] as Prices;
                                //@ts-ignore
                                const packageDependentPricesArrayEngine = engineOption.prices[currentPackage] ? engineOption.prices[currentPackage] : engineOption.prices["*"] as Prices;
                                //Sum up all prices of packageDependentPricesArrayModel and packageDependentPricesArrayEngine
                                //@ts-ignore
                                const sumPrice = packageDependentPricesArrayModel.reduce((acc, curr) => acc + curr[currentCurrency], 0) + packageDependentPricesArrayEngine.reduce((acc, curr) => acc + curr[currentCurrency], 0);

                                html += '<tr><td align="left"><small>' + modelOption.displayName[currentLanguage] + ' + ' + engineOption.displayName[currentLanguage] + '</small></td><td align="right"><small>' + formatPrice(sumPrice) + '</small></td></tr>';
                                listReturnElements.push(<li key={'first' + engineOptionIndex}><span>{modelOption.displayName[currentLanguage]} + {engineOption.displayName[currentLanguage]}</span><span>{formatPrice(sumPrice)}</span></li>);
                            }
                        })
                    }
                    //Additional entries for engine, aka. installation, inspection
                    else {
                        engineOptionGroup.options.forEach((option, engineOptionIndex) => {
                            if(option.conditionsOptionVisibility && !checkCondition(option.conditionsOptionVisibility, configuration)) return;
                            if (option.active) {
                                //@ts-ignore
                                const packageDependentPricesArray = option.prices[currentPackage] ? option.prices[currentPackage] : option.prices["*"] as Prices;
                                //@ts-ignore
                                const price = packageDependentPricesArray.reduce((acc, curr) => acc + curr[currentCurrency], 0);
                                html += '<tr><td align="left"><small>' + option.displayName[currentLanguage] + (option.mandatory ? ' *' : '') + '</small></td><td align="right"><small>' + formatPrice(price) + '</small></td></tr>';
                                listReturnElements.push(<li key={'engineifi' + engineOptionIndex}><span>{`${option.displayName[currentLanguage]} ${option.mandatory ? " *": ""}`}</span><span>{formatPrice(price)}</span></li>);
                            }
                        })
                    }
                });
                
                //Additional entries for model, aka. fridges etc
                if(modelOption.subOptions) {
                    modelOption.subOptions.forEach((subOption, subOptionIndex) => {
                        if(subOption.active) {
                            //@ts-ignore
                            const packageDependentPricesArray = subOption.prices[currentPackage] ? subOption.prices[currentPackage] : subOption.prices["*"] as Prices;
                            //@ts-ignore
                            const price = packageDependentPricesArray.reduce((acc, curr) => acc + curr[currentCurrency], 0);
                            html += '<tr><td align="left"><small>' + subOption.displayName[currentLanguage] + '</small></td><td align="right"><small>' + formatPrice(price) + '</small></td></tr>';
                            listReturnElements.push(<li key={"suboption" + subOptionIndex}><span>{subOption.displayName[currentLanguage]}</span><span>{formatPrice(price)}</span></li>);
                        }
                    })
                }
            }
        })
    })
    
    if(mode === "html") return html;
    if(mode === "jsx") return (
        <div className={'lightbox-content-summary__category'}>
            {returnElements}
            <ul className={'lightbox-content-summary__option-group'}>
                {listReturnElements}
            </ul>
        </div>
    );
}

export const returnConfigurationSummary = (mode: "jsx" | "html", configuration: IProcessedConfigurationCategory[], currentLanguage: ELanguage, currentCurrency: ECurrencies, basicEquipmentPackage: EPackage) => {
    let html = '';
    const jsxArray: JSX.Element[] = [];
    configuration.forEach((category, categoryIndex) => {
        if(category.name === "model" || category.name === "engines") return null;

        const categoryJsxArray: JSX.Element[] = [];
        if(checkIfCategoryHasActiveOptions(category, configuration)) {
            categoryJsxArray.push(<h2>{category.displayName[currentLanguage]}</h2>) 
            html += '<tr><td>&nbsp;</td><td>&nbsp;</td></tr>';
            html += '<tr><td><b>' + category.displayName[currentLanguage] + ':</b></td><td></td></tr>';
        }

        category.optionGroups.forEach((optionGroup) => {
            if(optionGroup.conditionsOptionVisibility && !checkCondition(optionGroup.conditionsOptionVisibility, configuration)) return;
            
            const optionGroupJsxArray: JSX.Element[] = [];
            optionGroup.options.forEach((option, optionIndex) => {
                if(option.conditionsOptionVisibility && !checkCondition(option.conditionsOptionVisibility, configuration)) return;
                
                if (option.active) {
                    //@ts-ignore
                    const packageDependentPricesArray = option.prices[basicEquipmentPackage] ? option.prices[basicEquipmentPackage] : option.prices["*"] as Prices;
                    
                    html += '<tr><td align="left"><small>' + option.displayName[currentLanguage] + '</small></td><td align="right"><small>' + formatPrice(packageDependentPricesArray[0][currentCurrency]) + '</small></td></tr>';
                    optionGroupJsxArray.push(<li className={`${packageDependentPricesArray[0][currentCurrency] === 0 ? "--no-price" : ""}`}>
                            <span>{option.displayName[currentLanguage]}</span><span>{formatPrice(packageDependentPricesArray[0][currentCurrency])}</span>
                        </li>
                    );
                    
                    //@ts-ignore
                    (option.prices[basicEquipmentPackage] ? option.prices[basicEquipmentPackage] : option.prices["*"]).forEach((price, priceIndex) => {
                        if (priceIndex !== 0) {
                            html += '<tr><td align="left"><small>' + price['info'][currentLanguage] + '</small></td><td align="right"><small>' + formatPrice(price[currentCurrency]) + '</small></td></tr>';
                            optionGroupJsxArray.push(<li><span>{price['info'][currentLanguage]}</span><span>{formatPrice(price[currentCurrency])}</span></li>)
                        }
                    })

                    option.subOptions && option.subOptions.forEach(subOption => {
                        if (subOption.active) {
                            //@ts-ignore
                            html += '<tr><td align="left"><small>' + subOption.displayName[currentLanguage] + '</small></td><td align="right"><small>' + formatPrice((subOption.prices[basicEquipmentPackage] ? subOption.prices[basicEquipmentPackage] : subOption.prices["*"])[0][currentCurrency]) + '</small></td></tr>';
                            //@ts-ignore
                            optionGroupJsxArray.push(<li><span>{subOption.displayName[currentLanguage]}</span><span>{formatPrice((subOption.prices[basicEquipmentPackage] ? subOption.prices[basicEquipmentPackage] : subOption.prices["*"])[0][currentCurrency])}</span></li>);
                        }
                    })
                }
            })

            categoryJsxArray.push(<ul className={'lightbox-content-summary__option-group'}>{optionGroupJsxArray}</ul>)
        });
        
        jsxArray.push(
            <div className={'lightbox-content-summary__category'}>
                {categoryJsxArray}
            </div>
        )
    });
    
    if (mode === "html") return html;
    if (mode === "jsx") return jsxArray;
}

export const returnSummaryTotal = (mode: "jsx" | "html", configuration: IProcessedConfigurationCategory[], currentLanguage: ELanguage, currentCurrency: ECurrencies, basicEquipmentPackage: EPackage, isGerman: boolean) => {
    let html = '';
    const totalSum = formatPrice(calculatePriceFromConfiguration(configuration, currentCurrency, basicEquipmentPackage));
    if(mode === "jsx") {
        return (
            <>
                <span className={'lightbox-content-summary__mandatory-hint'} dangerouslySetInnerHTML={{__html: `* ${DATA.locale.mandatoryOptions[currentLanguage]}`}}></span>
                <div className={'lightbox-content-summary__total'}>
                    <h1>{DATA.locale.total[currentLanguage]}</h1>
                    <span>{totalSum}</span>
                </div>
                <div className={'lightbox-content-summary__additional-info'}>
                    {isGerman ? <div dangerouslySetInnerHTML={{__html: DATA.locale.VAT19included[currentLanguage]}}/> : null}
                    {!isGerman ? <div dangerouslySetInnerHTML={{__html: DATA.locale.noVATinfo[currentLanguage]}}/> : null}
                    {basicEquipmentPackage === EPackage.SPORT ? <div dangerouslySetInnerHTML={{__html: DATA.locale.noTransportSport[currentLanguage]}}/> : null}
                    {basicEquipmentPackage === EPackage.SUPERSPORT ? <div dangerouslySetInnerHTML={{__html: DATA.locale.noTransportSupersport[currentLanguage]}}/> : null}
                    <div dangerouslySetInnerHTML={{__html: DATA.locale.priceChangeInfo[currentLanguage]}}/>
                </div>
            </>
        )
    }
    if (mode === "html") {
        
        html += '<tr><td>&nbsp;</td><td>&nbsp;</td></tr>';
        html += '<tr><td><h3>' + DATA.locale.total[currentLanguage] + '</h3></td><td align="right"><b>' + totalSum + '</b></td></tr>';
        html += '<tr><td>&nbsp;</td><td>&nbsp;</td></tr>';
        html += `<tr><td><small>* ${DATA.locale.mandatoryOptions[currentLanguage]}</small></td><td>&nbsp;</td></tr>`;
        if(isGerman) {
            html += '<tr><td colspan="2"><small>' + DATA.locale.VAT19included[currentLanguage] + '</small></td></tr>';
        }
        else if(!isGerman) {
            html += '<tr><td colspan="2"><small>' + DATA.locale.noVATinfo[currentLanguage] + '</small></td></tr>';
        }
        html += '<tr><td colspan="2"><small>' + DATA.locale.priceChangeInfo[currentLanguage] + '</small></td></tr>';

        html += '</table>';
        
        return html;
    }
}

function LightboxContentSummary(props: ILightboxContentSummaryProps) {
    const {currentCurrency, currentLanguage, isGerman, isEurope} = useGlobalStore(state => state);
    const {basicEquipmentPackage, configuration} = useConfigurationStore(state => state); 

    return (
        <div className={'lightbox-content-summary'}>
            <div className={'lightbox-content-summary__header'}>
                {basicEquipmentPackage === EPackage.SPORT ? <h1><PackageIcon type={EPackage.SPORT}/><span>Sterk31 Sport</span></h1> : null}
                {basicEquipmentPackage === EPackage.SUPERSPORT ? <h1><PackageIcon type={EPackage.SUPERSPORT}/><span>Sterk31 Supersport</span></h1> : null}
            </div>
            {returnSpecialModelEngineGroup("jsx", configuration, currentLanguage, currentCurrency, basicEquipmentPackage)}
            {returnConfigurationSummary("jsx", configuration, currentLanguage, currentCurrency, basicEquipmentPackage)}
            {returnSummaryTotal("jsx", configuration, currentLanguage, currentCurrency, basicEquipmentPackage, isGerman)}
        </div>
    );
}

export default LightboxContentSummary;
