import {ECurrencies, EPackage, IClickOptionConfig, IJsepTree, IProcessedConfigurationCategory, IProcessedConfigurationOption, IProcessedOptionGroup, IProcessedSceneActionHotspot, NestedObject,} from "../types/types";
import {STEPS_BEFORE_CONFIGURATION} from "./globals";
import DATA from "../data/data.json";
import jsep from "jsep";
import {useGlobalStore} from "../store/store";

export const VATValue = 19;

/**
 * Makes sure all active options that are not available in the given package are set to inactive, while finding replacement options if necessary.
 */
export function returnConfigurationAfterPackageChange(configuration: IProcessedConfigurationCategory[], currentPackage: EPackage): IProcessedConfigurationCategory[] {
    let currentConfiguration = structuredClone(configuration) as IProcessedConfigurationCategory[];
    
    currentConfiguration.forEach((category) => {
        category.optionGroups.forEach((optionGroup) => {
            optionGroup.options.forEach((option) => {
                if (!option.availableInPackages.includes(currentPackage)) {
                    //Deactivate
                    if (option.active) {
                        option.active = false;
                        if(option.subOptions) {
                            option.subOptions.forEach((subOption) => {
                                subOption.active = false;
                            });
                        }
                    }
                    //Find replacement if not toggle
                    if(!optionGroup.toggle) {
                        let preferredStandardWasFound = false;
                        for(let i=0; i< optionGroup.options.length; i++) {
                            if(optionGroup.options[i].preferredStandardForPackages?.includes(currentPackage)) {
                                optionGroup.options[i].active = true;
                                preferredStandardWasFound = true;
                                break;
                            }
                        }
                        if(!preferredStandardWasFound) {
                            for (let i = 0; i < optionGroup.options.length; i++) {
                                if (optionGroup.options[i].availableInPackages.includes(currentPackage)) {
                                    optionGroup.options[i].active = true;
                                    break;
                                }
                            }
                        }
                    }
                }
            });
        });
    });
    
    
    return currentConfiguration;
}

/**
 * Returns a new configuration array with the option at the given index set to active:true and its siblings set to active:false.
 */
export function returnConfigurationAfterOptionClick(categoryIndex: number, optionIndexes: [number, number, number?], configuration: IProcessedConfigurationCategory[], optionConfig?: IClickOptionConfig): IProcessedConfigurationCategory[] {
    let defaultConfig = {
        toggle: false,
        deactivateSiblings: true,
    };
    optionConfig = {...defaultConfig, ...optionConfig};

    let currentConfiguration = structuredClone(configuration) as IProcessedConfigurationCategory[];
    let selectedOption = currentConfiguration[categoryIndex].optionGroups[optionIndexes[0]].options[optionIndexes[1]];
    let selectedSubOption: IProcessedConfigurationOption | undefined = undefined;
    if (optionIndexes[2] !== undefined) {
        selectedSubOption = currentConfiguration[categoryIndex].optionGroups[optionIndexes[0]].options[optionIndexes[1]].subOptions?.[optionIndexes[2]];
    }
    if (selectedSubOption === undefined) {
        //Set option to active if optionConfig.toggle === false, otherwise toggle it
        selectedOption.active = optionConfig.toggle ? !selectedOption.active : true;

        //Set all siblings to active:false if necessary, don't touch the actual object in question since it was just set correctly
        if (optionConfig.deactivateSiblings) {
            currentConfiguration[categoryIndex].optionGroups[
                optionIndexes[0]
                ].options.map((option) => {
                if (option.name !== selectedOption.name) {
                    option.active = false;
                }
            });
        }
    }
    else {
        selectedSubOption.active = optionConfig.toggle
            ? !selectedSubOption.active
            : true;

        //Set all siblings to active:false if necessary, don't touch the actual object in question since it was just set correctly
        if (optionConfig.deactivateSiblings) {
            currentConfiguration[categoryIndex].optionGroups[
                optionIndexes[0]
                ].options[optionIndexes[1]].subOptions?.map((subOption) => {
                if (selectedSubOption && subOption.name !== selectedSubOption.name) {
                    subOption.active = false;
                }
            });
        }
    }

    
    
    return currentConfiguration
}

/**
 * Goes through the configuration and sets the displayedPrice of each option based on the active option in the group.
 */
export function returnConfigurationWithUpdatedPrices(configuration: IProcessedConfigurationCategory[], currentPackage: EPackage, activeCurrency: ECurrencies) {
    let updatedConfiguration = structuredClone(configuration) as IProcessedConfigurationCategory[];
    const currentSumPrice = calculatePriceFromConfiguration(updatedConfiguration, activeCurrency, currentPackage);
    
    updatedConfiguration.forEach((category, categoryIndex) => {
        category.optionGroups.forEach((optionGroup, optionGroupIndex) => {
            let activeOption: undefined | IProcessedConfigurationOption = undefined;
            let activeOptionPrice = 0;

            //If options exclude each other
            if(!optionGroup.toggle) {
                activeOption = optionGroup.options.find((option) => option.active);
                if(activeOption) {
                    //@ts-ignore
                    const packageDependentPricesArray = activeOption.prices[currentPackage] ? activeOption.prices[currentPackage] : activeOption.prices["*"];
                    //@ts-ignore
                    activeOptionPrice = packageDependentPricesArray.reduce((acc, price) => acc + price[activeCurrency], 0);
                }
            }

            optionGroup.options.forEach((option, optionIndex) => {
                if(option.active) {
                    option.displayedPrice = 0;
                }
                else {
                    //@ts-ignore
                    const packageDependentPricesArray = option.prices[currentPackage] ? option.prices[currentPackage] : option.prices["*"];
                    //@ts-ignore
                    const optionPrice = packageDependentPricesArray.reduce((acc, price) => acc + price[activeCurrency], 0);
                    
                    //If option would cause a package change
                    if(!option.availableInPackages.includes(currentPackage)) {
                        const otherPackage = currentPackage === EPackage.SPORT ? EPackage.SUPERSPORT : EPackage.SPORT;
                        const hypotheticalConfiguration = returnConfigurationAfterPackageChange(returnConfigurationAfterOptionClick(categoryIndex, [optionGroupIndex,optionIndex], updatedConfiguration, {toggle: optionGroup.toggle, deactivateSiblings: !optionGroup.toggle}), otherPackage);
                        const hypotheticalPrice = calculatePriceFromConfiguration(hypotheticalConfiguration, activeCurrency, otherPackage);
                        option.displayedPrice = hypotheticalPrice - currentSumPrice;
                    }
                    else {
                        //If options exclude each other
                        if(!optionGroup.toggle) {
                            option.displayedPrice = optionPrice - activeOptionPrice;
                        }
                        else {
                            option.displayedPrice = optionPrice;
                        }
                    }
                }

                if (option.subOptions !== undefined) {
                    let activeSubOption: undefined | IProcessedConfigurationOption = undefined;
                    let activeSubOptionPrice = 0;

                    //If subOptions exclude each other
                    if(!option.subOptionsToggle) {
                        activeSubOption = option.subOptions.find((option) => option.active);
                        if(activeSubOption) {
                            //@ts-ignore
                            const packageDependentPricesArray = activeSubOption.prices[currentPackage] ? activeSubOption.prices[currentPackage] : activeSubOption.prices["*"];
                            //@ts-ignore
                            activeSubOptionPrice = packageDependentPricesArray.reduce((acc, price) => acc + price[activeCurrency], 0);
                        }
                    }

                    option.subOptions.forEach((subOption) => {
                        if(subOption.active) {
                            subOption.displayedPrice = 0;
                        }
                        else {
                            //Price of option
                            //@ts-ignore
                            const packageDependentPricesArray = subOption.prices[currentPackage] ? subOption.prices[currentPackage] : subOption.prices["*"];
                            //@ts-ignore
                            const subOptionPrice = packageDependentPricesArray.reduce((acc, price) => acc + price[activeCurrency], 0);

                            //If options exclude each other
                            if(!option.subOptionsToggle) {
                                subOption.displayedPrice = subOptionPrice - activeSubOptionPrice;
                            }
                            else {
                                subOption.displayedPrice = subOptionPrice;
                            }
                        }
                    });
                }
            });
        });
    });

    return updatedConfiguration;
}

/**
 * Returns a new sceneActionHotspot array with the hotspot at the given index set to active:true or active:false.
 */
export function returnNewSceneActionHotspotsAfterHotspotClick(hotspotIndex: number, sceneActionHotspots: IProcessedSceneActionHotspot[], optionConfig?: { deactivate?: boolean; activate?: boolean }): IProcessedSceneActionHotspot[] {
    let defaultConfig = {
        deactivate: false,
        activate: false,
    };
    optionConfig = {...defaultConfig, ...optionConfig};

    let currentSceneActionHotspots = structuredClone(
        sceneActionHotspots
    ) as IProcessedSceneActionHotspot[];
    let selectedHotspot = currentSceneActionHotspots[hotspotIndex];

    if (optionConfig.deactivate) {
        selectedHotspot.active = false;
        selectedHotspot.animationState = "shouldStart";
        return currentSceneActionHotspots;
    }
    if (optionConfig.activate) {
        selectedHotspot.active = true;
        selectedHotspot.animationState = "shouldStart";
        return currentSceneActionHotspots;
    }

    selectedHotspot.active = !selectedHotspot.active;
    selectedHotspot.animationState = "shouldStart";

    return currentSceneActionHotspots;
}

export function returnNewSceneActionHotspotsAfterAnimationStart(hotspotIndex: number, sceneActionHotspots: IProcessedSceneActionHotspot[]): IProcessedSceneActionHotspot[] {
    let currentSceneActionHotspots = structuredClone(sceneActionHotspots) as IProcessedSceneActionHotspot[];
    let selectedHotspot = currentSceneActionHotspots[hotspotIndex];

    selectedHotspot.animationState = "isRunning";

    return currentSceneActionHotspots;
}

export function returnNewSceneActionHotspotsAfterAnimationEnd(hotspotIndex: number, sceneActionHotspots: IProcessedSceneActionHotspot[]): IProcessedSceneActionHotspot[] {
    let currentSceneActionHotspots = structuredClone(sceneActionHotspots) as IProcessedSceneActionHotspot[];
    let selectedHotspot = currentSceneActionHotspots[hotspotIndex];

    selectedHotspot.animationState = "isStopped";

    return currentSceneActionHotspots;
}

export function setNestedProperty<T>(obj: NestedObject<T>, path: string, value: T): void {
    const properties: string[] = path.split(".");
    let currentObj: NestedObject<T> = obj;

    for (let i = 0; i < properties.length - 1; i++) {
        const property = properties[i];
        if (!currentObj[property] || typeof currentObj[property] !== "object") {
            currentObj[property] = {};
        }
        currentObj = currentObj[property] as NestedObject<T>;
    }

    currentObj[properties[properties.length - 1]] = value;
}

export function getNestedProperty<T>(obj: NestedObject<T>, path: string): T | undefined {
    const properties: string[] = path.split(".");
    let currentObj: NestedObject<T> = obj;

    properties.forEach((property, index) => {
        if (
            (!currentObj[property] || typeof currentObj[property] !== "object") &&
            index < properties.length - 1
        ) {
            return undefined;
        }
        currentObj = currentObj[property] as NestedObject<T>;
    });

    return currentObj as T;
}

export function returnRomanNumeral(number: number): string {
    switch (number) {
        case 0:
            return "I";
        case 1:
            return "II";
        case 2:
            return "III";
        case 3:
            return "IV";
        case 4:
            return "V";
        case 5:
            return "VI";
        case 6:
            return "VII";
        case 7:
            return "VIII";
        case 8:
            return "IX";
        case 9:
            return "X";
        case 10:
            return "XI";
        default:
            return "";
    }
}

/**
 * Two-point-form of the line equation
 */
export function mapRange(x: number, p1x: number, p1y: number, p2x: number, p2y: number) {
    return ((p2y - p1y) / (p2x - p1x)) * (x - p1x) + p1y;
}

export function hexToRgb(hex: string) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        }
        : null;
}

/**
 * Removes extension from a filename string (ie. "foo.jpg") and returns the filename (ie. "foo")
 * @param filename
 */
export function removeExtension(filename: string) {
    return filename.split(".").slice(0, -1).join(".");
}

/**
 * Gets an object from the configuration-options-array by name
 */
export function getObjectByName(configuration: IProcessedConfigurationCategory[], name: string): IProcessedConfigurationOption | null {
    let result = null;

    configuration.map((category) => {
        category.optionGroups.map((optionGroup) => {
            optionGroup.options.map((option) => {
                if (option.name === name) {
                    result = option;
                    return result;
                }
                if (option.subOptions !== undefined) {
                    option.subOptions.map((subOption) => {
                        if (subOption.name === name) {
                            result = subOption;
                            return result;
                        }
                    });
                }
            });
        });
    });

    return result;
}

/**
 * The dataIndex is the index needed to get correct content from the configuration array.
 * It differs from the currentStepIndex since we've added steps before and after the configuration steps.
 * This is being normalized here.
 */
export function stepIndexToDataIndex(currentStepIndex: number) {
    let dataIndex = currentStepIndex - STEPS_BEFORE_CONFIGURATION > 0 ? currentStepIndex - STEPS_BEFORE_CONFIGURATION : 0;
    if (dataIndex > DATA.configurationCategories.length - 1) {
        dataIndex = DATA.configurationCategories.length - 1;
    }

    return dataIndex;
}

/**
 * Returns a number whose value is limited to the given range.
 *
 * Example: limit the output of this computation to between 0 and 255
 * (x * 255).clamp(0, 255)
 *
 * @param {Number} value Value to clamp
 * @param {Number} min The lower boundary of the output range
 * @param {Number} max The upper boundary of the output range
 * @returns A number in the range [min, max]
 * @type Number
 */
export function clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
}

export function checkCondition(condition: string, configuration: IProcessedConfigurationCategory[]) {
    const parsedTree = jsep(condition);

    const evaluatedTree = evaluateJsepTree(parsedTree, configuration);

    return evaluatedTree;
}

export function evaluateJsepTree(parsedTree: IJsepTree, configuration: IProcessedConfigurationCategory[]): boolean {
    if (parsedTree.type === "Compound") return true;

    //Simple option name: "tTop"
    if (parsedTree.type === "Identifier" && parsedTree.name !== undefined) {
        const option = getObjectByName(configuration, parsedTree.name);
        if (option !== null && option.active) {
            return true;
        }
    }
    //Negated option name: "!tTop"
    if (parsedTree.type === "UnaryExpression" && parsedTree.operator === "!" && parsedTree.argument !== undefined) {
        //@ts-ignore
        const option = getObjectByName(configuration, parsedTree.argument.name);
        if (option !== null && !option.active) {
            return true;
        }
    }
    //Binary expressions: "tTop && tTop2" or "!tTop && tTop2"
    if (parsedTree.type === "BinaryExpression" && parsedTree.operator === "&&" && parsedTree.left !== undefined && parsedTree.right !== undefined) {
        return (
            evaluateJsepTree(parsedTree.left, configuration) &&
            evaluateJsepTree(parsedTree.right, configuration)
        );
    }
    //Binary expressions: "tTop || tTop2" or "!tTop || tTop2"
    if (parsedTree.type === "BinaryExpression" && parsedTree.operator === "||" && parsedTree.left !== undefined && parsedTree.right !== undefined) {
        return (
            evaluateJsepTree(parsedTree.left, configuration) ||
            evaluateJsepTree(parsedTree.right, configuration)
        );
    }

    return false;
}

export function calculatePriceFromConfiguration(configurationCategories: IProcessedConfigurationCategory[], activeCurrency: ECurrencies, currentPackage: EPackage) {
    let price = 0;
    
    configurationCategories.forEach((category) => {
        category.optionGroups.forEach((optionGroup) => {
            optionGroup.options.forEach((option) => {
                if(option.conditionsOptionVisibility && !checkCondition(option.conditionsOptionVisibility, configurationCategories)) return;
                
                if (option.active) {
                    //@ts-ignore
                    const packageDependentPricesArray = option.prices[currentPackage] ? option.prices[currentPackage]! : option.prices["*"]!;
                    //@ts-ignore
                    price += packageDependentPricesArray.reduce((acc, price) => acc + price[activeCurrency], 0);

                    if (option.subOptions !== undefined) {
                        option.subOptions.forEach((subOption) => {
                            if (subOption.active) {
                                //@ts-ignore
                                const packageDependentPricesArray = subOption.prices[currentPackage] ? subOption.prices[currentPackage] : subOption.prices["*"];
                                //@ts-ignore
                                price += packageDependentPricesArray.reduce((acc, price) => acc + price[activeCurrency], 0);
                            }
                        });
                    }
                }
            });
        });
    });

    return price;
}

export function formatPrice(price: number, addSign = false, zeroIsIncluded = false) {
    const currentCurrency = useGlobalStore.getState().currentCurrency;
    const isGerman = useGlobalStore.getState().isGerman;
    const isEurope = useGlobalStore.getState().isEurope;
    let sign = "";
    if(addSign) {
        if(price >=0) {
            sign = "+ ";
        }
        else {
            sign = "- ";
        }
    }
    
    if(price === 0) {
        if(zeroIsIncluded) {
            return " -- included";
        }
        else {
            return "";
        }
    }
    else {
        if(isEurope && !isGerman) {
            price = Math.floor((price * 100/(100 + VATValue) * 100)/100);
        }
        
        return sign + new Intl.NumberFormat(currentCurrency === ECurrencies.EUR ? 'de-DE' : 'en-US', { style: 'currency', currency: currentCurrency }).format(Math.abs(price));
    }
}

export function checkIfCategoryHasActiveOptions(category: IProcessedConfigurationCategory, configuration: IProcessedConfigurationCategory[]) {
    let hasActiveOptions = false;

    category.optionGroups.forEach((optionGroup) => {
        if(optionGroup.conditionsOptionVisibility && !checkCondition(optionGroup.conditionsOptionVisibility, configuration)) return;
        optionGroup.options.forEach((option) => {
            if(option.conditionsOptionVisibility && !checkCondition(option.conditionsOptionVisibility, configuration)) return;
            if (option.active) {
                hasActiveOptions = true;
            }
        });
    });

    return hasActiveOptions;
}

export function returnFormattedLowestPrice(currentPackage: EPackage, isEurope: boolean, isGerman: boolean) {
    if(currentPackage === EPackage.SPORT) {
        if(isGerman) {
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: "EUR" }).format(Math.abs(143871))
        }
        else if(isEurope) {
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: "EUR" }).format(Math.abs(120900))
        }
        else if(!isEurope) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: "USD" }).format(Math.abs(166511))
        }
    }
    else if(currentPackage === EPackage.SUPERSPORT) {
        if(isGerman) {
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: "EUR" }).format(Math.abs(196707))
        }
        else if(isEurope) {
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: "EUR" }).format(Math.abs(165300))
        }
        else if(!isEurope) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: "USD" }).format(Math.abs(222026))
        }
    }
}

export function returnConfigurationWithActivatedMandatoryOptionsAtIndex(dataIndex: number, configuration: IProcessedConfigurationCategory[], currentPackage: EPackage) {
    let currentConfiguration = structuredClone(configuration) as IProcessedConfigurationCategory[];
 
    currentConfiguration[dataIndex].optionGroups.forEach((optionGroup) => {
        if(!optionGroup.toggle) return;
        
        optionGroup.options.forEach((option) => {
            if(option.mandatory) {
                if(option.conditionsOptionVisibility && !checkCondition(option.conditionsOptionVisibility, currentConfiguration)) return;
                if(!option.availableInPackages.includes(currentPackage)) return;
                
                option.active = true;
            }
        });
    });
    
    return currentConfiguration;
}

export function optionGroupContainsMandatoryOptions(optionGroup: IProcessedOptionGroup, configuration: IProcessedConfigurationCategory[]) {
    let hasMandatoryOptions = false;
    
    optionGroup.options.forEach((option) => {
        if(option.conditionsOptionVisibility && !checkCondition(option.conditionsOptionVisibility, configuration)) return;
        
        if (option.mandatory) {
            hasMandatoryOptions = true;
        }
    });
    
    console.log("Has ma: ", hasMandatoryOptions);

    return hasMandatoryOptions;
}