import React from 'react';
import './Formfields.scss';
import { ImCheckmark } from 'react-icons/im';
interface IInputCheckboxProps {
    id: string;
    name: string;
    checked: boolean;
    labelText: string;
    index: number;
    required?: boolean;
    disabled?: boolean;
    handleFormFieldChange: (index: number, value: any) => void;
}

function InputCheckbox(props: IInputCheckboxProps) {
    return (
        <div className='checkbox-single'>
            <div className='checkbox'>
                <input
                    type='checkbox'
                    id={props.id}
                    value={props.labelText}
                    name={props.name}
                    checked={props.checked}
                    required={props.required}
                    disabled={props.disabled}
                    onChange={event => {
                        props.handleFormFieldChange(props.index, { type: 'checkbox', checked: !props.checked });
                    }}
                />
                <div className='checkbox__checkmark'>
                    <div className={`checkbox__checkmark-inner`}>{props.checked && <ImCheckmark />}</div>
                </div>
                <label dangerouslySetInnerHTML={{ __html: props.labelText }}></label>
            </div>
        </div>
    );
}

export default InputCheckbox;
