import { Texture } from "three";
import {
    IFormFieldCheckboxProcessed,
    IFormFieldGenericProcessed,
    IFormFieldRadioGroupProcessed,
    IFormFieldSelectProcessed,
} from "./formTypes";
import {ThreeD} from "../components/ThreeDCanvas/ThreeD";

export type NestedObject<T> = {
    [key: string]: T | NestedObject<T>;
};

export interface IJsepTree {
    type: string;
    name?: string;
    argument?: {
        name: string;
    };
    operator?: string;
    left?: IJsepTree;
    right?: IJsepTree;
}

export type ThreeDReference = {
    threeD: ThreeD | null;
};

export type ConfigurationState = {
    configuration: IProcessedConfigurationCategory[];
    basicEquipmentPackage: EPackage;
};

export type GlobalState = {
    isGerman: boolean;
    isEurope: boolean;
    currentStepIndex: number;
    highestVisitedStepIndex: number;
    isLoading: boolean;
    loadedPercentage: number;
    currentLanguage: ELanguage;
    currentCurrency: ECurrencies;
    contactWindowState: "form" | "spinner" | "messageSuccess" | "messageError";
    emailSendStatusCode: number;
    camOffset: {
        x: number;
        y: number;
    };
    viewport: {
        width: number;
        height: number;
    };
    optionWasSelectedOnce: boolean;
    saveConfigurationOverlayIsOpen: boolean;
    summaryOverlayIsOpen: boolean;
    fullscreenIsActive: boolean;
    baseUrl: string;
};

export enum ECurrencies {
    EUR = "EUR",
    USD = "USD",
}

export enum EPackage {
    SPORT = "sport",
    SUPERSPORT = "supersport",
}

export enum ELanguage {
    EN = "en",
    DE = "de",
}

export function isEPackage(object: any): object is EPackage {
    return object === EPackage.SPORT || object === EPackage.SUPERSPORT;
}

export type Prices = {"*": TPackagePricesArray} | {[key in EPackage ]: TPackagePricesArray;}

export type TPackagePricesArray = Array<{
    info?: TLanguageString
} & {[key in ECurrencies]: number}>; 

export type SceneActionHotspotsState = {
    sceneActionHotspots: IProcessedSceneActionHotspot[];
};

export type FormFieldState = {
    formFields: (
        | IFormFieldGenericProcessed
        | IFormFieldSelectProcessed
        | IFormFieldRadioGroupProcessed
        | IFormFieldCheckboxProcessed
        )[];
};

export interface IThreeDConfig {
    frameRate: number;
    maxResolution: number;
    debug: boolean;
    containObjectInViewParams: {
        portraitAspectRatio: number;
        portraitZoom: number;
        landscapeAspectRatio: number;
        landscapeZoom: number;
    };
    logCamPosition: boolean;
    showGridHelper: boolean;
    showLightHelpers: boolean;
    showCamTarget: boolean;
    startCameraPosition: ICameraPositionAllViewports;
}

export interface ILoadingStats {
    gltfLoaded: number;
    gltfTotal: number;
    rgbeLoaded: number;
    rgbeTotal: number;
    gltfWasLoaded: boolean;
    rgbeWasLoaded: boolean;
}

export interface ICameraPosition {
    target: IPosition3D;
    camera: IPosition3D;
}

export interface IClickOptionConfig {
    toggle?: boolean;
    deactivateSiblings?: boolean 
}

export interface ICameraPositionAllViewports {
    landscape: ICameraPosition;
    portrait?: ICameraPosition;
}

export interface ThreeDOptions {
    canvas: HTMLCanvasElement;
    hotspotRefs?: any[];
}

export type TSceneAction = ISceneActionTexture | ISceneActionColor | ISceneActionMaterial | ISceneActionVisibility | ISceneActionPosition | ISceneActionBackground | ISceneActionParameterAnimation;

export interface ISceneActionVisibility {
    type: "visibility";
    visibleMeshNames: string[];
    invisibleMeshNames: string[];
}

export function isISceneActionVisibility(object: any): object is ISceneActionVisibility {
    return object.type === "visibility";
}

export interface ISceneActionBackground {
    type: "background";
    gradientColor1: string;
    gradientColor2: string;
    fontInverted: boolean;
}

export function isISceneActionBackground(object: any): object is ISceneActionBackground {
    return object.type === "background";
}

export interface ISceneActionPosition {
    type: "position";
    meshNames: string[];
    position: IPosition3D;
}

export type TTextureProperty = "map" | "envMap" | "aoMap" | "emissiveMap" | "normalMap" | "bumpMap" | "roughnessMap" | "metalnessMap" | "alphaMap";

export interface ISceneActionTexture {
    type: "texture";
    materialNames: string[];
    textureProperty: TTextureProperty;
    textureFileName: string;
    repeatU: number;
    repeatV: number;
}

export interface ITextureCache {
    [key: string]: Texture;
}

export function isISceneActionTexture(object: any): object is ISceneActionTexture {
    return object.type === "texture";
}

export interface ISceneActionMaterial {
    type: "material";
    meshNames: string[];
    materialName: string;
}

export function isISceneActionMaterial(object: any): object is ISceneActionMaterial {
    return object.type === "material";
}

export interface ISceneActionColor {
    type: "color";
    materialNames: string[];
    RGBValues?: number[];
    RGBValuesNormalized?: number[];
    RALCode?: string;
}

export function isISceneActionColor(object: any): object is ISceneActionColor {
    return (
        object.type === "color" &&
        (object.RGBValues || object.RALCode || object.RGBValuesNormalized)
    );
}

export interface ISceneActionParameterAnimation {
    type: "parameterAnimation";
    objectName: string;
    parameter: string;
    value: number;
    duration: number;
}

export function isISceneActionParameterAnimation(object: any): object is ISceneActionParameterAnimation {
    return object.type === "parameterAnimation";
}

export interface ISceneActionStudioRamp {
    type: "studioRamp";
    hexValue: string;
}

export type TOptionType = "thumbnailWithText" | "longThumbnailWithText" | "textOnly" | "sub_colorSwatch" | "sub_textOnly";

export type TActiveOverride = [string, string, string, string?];

export type TLanguageString = { [key in ELanguage]: string };

export interface IPosition3D {
    x: number;
    y: number;
    z: number;
}

export interface IConfigurationCategory {
    name: string;
    displayName: TLanguageString;
    descriptionHtml: TLanguageString;
    cameraPositionData: ICameraPositionAllViewports;
    optionGroups: IOptionGroup[];
}

export interface IOptionGroup {
    name: string;
    optionGroupTitle?: TLanguageString;
    options: IConfigurationOption[];
    toggle: boolean;
    conditionsOptionVisibility?: string;
}

export interface IProcessedOptionGroup extends IOptionGroup {
    options: IProcessedConfigurationOption[];
}

export function isIProcessedOptionGroup(object: any): object is IProcessedOptionGroup {
    return object.options !== undefined;
}

export interface IConfigurationOption {
    optionType: TOptionType;
    name: string;
    displayName: TLanguageString;
    descriptionHtml?: TLanguageString;
    thumbnailUrl?: string;
    thumbnailColor?: string;
    conditionsAndSceneActions?: IConditionsAndSceneActions[];
    conditionsAndSceneActionsActive?: IConditionsAndSceneActions[];
    conditionsAndSceneActionsInactive?: IConditionsAndSceneActions[];
    conditionsOptionVisibility?: string;
    active?: boolean;
    mandatory?: boolean;
    subOptions?: IConfigurationOption[];
    subOptionsToggle?: boolean;
    cameraPositionData?: ICameraPositionAllViewports;
    prices: Prices;
    availableInPackages: string[];
    preferredStandardForPackages?: EPackage[];
}

export interface IConfigurationSceneActionHotspot {
    name: string;
    displayNameActive: TLanguageString;
    displayNameInactive: TLanguageString;
    conditionsAndSceneActionsActive?: IConditionsAndSceneActions[];
    conditionsAndSceneActionsInactive?: IConditionsAndSceneActions[];
    visibleAtStepIndexes: number[];
    active?: boolean;
}

export interface IConditionsAndSceneActions {
    condition: string;
    sceneActions: (
        | ISceneActionTexture
        | ISceneActionColor
        | ISceneActionMaterial
        | ISceneActionVisibility
        | ISceneActionPosition
        | ISceneActionBackground
        | ISceneActionParameterAnimation
        )[];
}

export interface IProcessedConfigurationCategory extends IConfigurationCategory {
    optionGroups: IProcessedOptionGroup[];
}

export function isIProcessedConfigurationCategory(object: any): object is IProcessedConfigurationCategory {
    return object.optionGroups !== undefined;
}

export interface IProcessedConfigurationOption extends IConfigurationOption {
    active: boolean;
    subOptions?: IProcessedConfigurationOption[];
    subOptionsToggle?: boolean;
    displayedPrice: number;
}

export type TAnimationState = "shouldStart" | "isRunning" | "isStopped";

export interface ISceneActionHotspot {
    name: string;
    displayNameActive: TLanguageString;
    displayNameInactive: TLanguageString;
    conditionsAndSceneActionsActive: IConditionsAndSceneActions[];
    conditionsAndSceneActionsInactive: IConditionsAndSceneActions[];
    active?: boolean;
    animationState?: TAnimationState;
}

export interface IProcessedSceneActionHotspot extends ISceneActionHotspot {
    active: boolean;
    animationState: TAnimationState;
}
