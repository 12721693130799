import React from 'react';
import './Formfields.scss';

interface IRadioGroupProps {
    name: string;
    label: string;
    index: number;
    id: string;
    choices: IRadioGroupChoice[];
    handleFormFieldChange: (index: number, value: any) => void;
}

export interface IRadioGroupChoice {
    value: string;
    label: string;
    checked: boolean;
}

function RadioGroup(props: IRadioGroupProps) {
    return (
        <fieldset className='radio-button-group'>
            <legend>{props.label}</legend>
            <div className='radio-button-group__radio-wrap '>
                {props.choices.map((radio, radioIndex) => {
                    return (
                        <div key={radioIndex} className='radio-button'>
                            <input
                                type='radio'
                                onChange={() => {
                                    props.handleFormFieldChange(props.index, { type: 'radioGroup', checkedIndex: radioIndex });
                                }}
                                id={radio.value + radioIndex}
                                value={radio.value}
                                name={props.name}
                                checked={radio.checked}
                            />
                            <label htmlFor={radio.value + radioIndex}>{radio.label}</label>
                        </div>
                    );
                })}
            </div>
        </fieldset>
    );
}

export default RadioGroup;
