import { useEffect } from 'react';
import { ECurrencies } from '../../types/types';
import { useConfigurationStore, useGlobalStore } from '../../store/store';
import {calculatePriceFromConfiguration, formatPrice} from '../../utilities/utilities';
import { motion, useAnimationControls } from 'framer-motion';
import './GlobalPrice.scss';
import PackageIcon from "../PackageIcon/PackageIcon";

interface IGlobalPriceProps {}

function GlobalPrice(props: IGlobalPriceProps) {
    const {basicEquipmentPackage, configuration} = useConfigurationStore(state => state);
    const currency = useGlobalStore(state => state.currentCurrency);
    const calculatedPrice = calculatePriceFromConfiguration(configuration, currency, basicEquipmentPackage);
    const priceUpdateAnimationControls = useAnimationControls();

    useEffect(() => {
        priceUpdateAnimationControls.start({
            scale: [1,1.1,1],
            y: [0, -4, 0],
            transition: {
                duration: 0.3
            }
        });
    }, [calculatedPrice]);

    return (
        <motion.div className={'global-price'} animate={priceUpdateAnimationControls} onClick={() => {
            useGlobalStore.setState({summaryOverlayIsOpen: true});
        }}>
            <PackageIcon type={basicEquipmentPackage} />
            <span>{formatPrice(calculatedPrice)}</span>
        </motion.div>
    );
}

export default GlobalPrice;
