import React from 'react';
import Logo from '../Logo/Logo';
import './Header.scss';
import Progress from '../Progress/Progress';
import { useGlobalStore } from '../../store/store';
import LanguageMenu from '../LanguageMenu/LanguageMenu';
import SaveButton from '../SaveButton/SaveButton';
import GlobalPrice from '../GlobalPrice/GlobalPrice';

interface IHeaderProps {}

function Header(props: IHeaderProps) {
    const optionWasSelectedOnce = useGlobalStore(state => state.optionWasSelectedOnce);
    const viewport = useGlobalStore(state => state.viewport);

    if (viewport.width < 740) {
        return (
            <header className={'header header--mobile'}>
                <div className={'header__mobile-wrapper'}>
                    <div className={'header__mobile-wrapper-language'}>
                        <LanguageMenu />
                    </div>
                    <div className={'header__mobile-wrapper-logo'}>
                        <Logo />
                    </div>
                    <div className={'header__mobile-wrapper-save-button'}>{optionWasSelectedOnce && <SaveButton />}</div>
                </div>
                <Progress />
            </header>
        );
    } else {
        return (
            <header className={'header header--desktop'}>
                <Logo />
                <Progress />
                <GlobalPrice />
                {optionWasSelectedOnce && <SaveButton />}
                <LanguageMenu />
            </header>
        );
    }
}

export default Header;
