import {createPortal} from "react-dom";
import {useRef, useState} from "react";
import "./OptionWindow.scss";
import ThumbnailWithText from "../ThumbnailWithText/ThumbnailWithText";
import {useConfigurationStore, useGlobalStore, useThreeDReference} from "../../store/store";
import {checkCondition, formatPrice, optionGroupContainsMandatoryOptions, returnConfigurationAfterOptionClick, returnConfigurationAfterPackageChange, returnConfigurationWithActivatedMandatoryOptionsAtIndex, returnConfigurationWithUpdatedPrices, stepIndexToDataIndex} from "../../utilities/utilities";
import {ECurrencies, EPackage, IProcessedConfigurationCategory, ELanguage, IClickOptionConfig, IProcessedConfigurationOption} from "../../types/types";
import DATA from "../../data/data.json";
import LongThumbnailWithText from "../LongThumbnailWithText/LongThumbnailWithText";
import TextOnly from "../TextOnly/TextOnly";
import {BsArrowLeftShort} from "react-icons/bs";
import {motion, AnimatePresence} from "framer-motion";
import {FRAMER_MOTION_VARIANT_FROM_BOTTOM, FRAMER_MOTION_VARIANT_FROM_RIGHT} from "../../utilities/framerMotionVariants";
import {useRightWindowCamOffset} from "../../hooks/useRightWindowCamOffset";
import Lightbox from "../Lightbox/Lightbox";

interface IOptionWindowProps {}

function returnOptions(
    dataIndex: number, 
    configuration: IProcessedConfigurationCategory[], 
    currentLanguage: ELanguage, 
    basicEquipmentPackage: EPackage, 
    currentCurrency: ECurrencies,
    setSuperSportInfoIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setSuperSportInfoConfirmHandler: (handler: () => void) => void
) {
    return configuration[dataIndex].optionGroups.map(
        (optionGroup, optionGroupIndex) => {
            if(optionGroup.conditionsOptionVisibility && !checkCondition(optionGroup.conditionsOptionVisibility, configuration)) return;
            
            return (
                <div
                    key={`${dataIndex}wrap${optionGroupIndex}`}
                    className={"option-window__option-group"}
                >
                    {optionGroup.optionGroupTitle ? <h2 key={`${dataIndex}h2${optionGroupIndex}`} className={"option-window__option-group-title"} dangerouslySetInnerHTML={{__html: optionGroup.optionGroupTitle[currentLanguage]}}/> : null}
                    {optionGroup.options.map((item, index) => {
                        if(item.conditionsOptionVisibility && !checkCondition(item.conditionsOptionVisibility, configuration)) return;
                        if(basicEquipmentPackage === EPackage.SUPERSPORT && !item.availableInPackages.includes(basicEquipmentPackage)) return;
                        
                        const handleOptionClick = () => {
                            if(basicEquipmentPackage === EPackage.SPORT && !item.availableInPackages.includes(EPackage.SPORT)) {
                                const configurationWithActivatedOption = returnConfigurationAfterOptionClick(dataIndex,[optionGroupIndex, index],configuration, {toggle: optionGroup.toggle,deactivateSiblings: !optionGroup.toggle});
                                const configurationAfterPackageChange = returnConfigurationAfterPackageChange(configurationWithActivatedOption, EPackage.SUPERSPORT);
                                const configurationWithUpdatedPrices = returnConfigurationWithUpdatedPrices(configurationAfterPackageChange, EPackage.SUPERSPORT, currentCurrency);

                                setSuperSportInfoIsOpen(true);
                                setSuperSportInfoConfirmHandler(() => () => {
                                    useConfigurationStore.setState({
                                        configuration: configurationWithUpdatedPrices,
                                        basicEquipmentPackage: EPackage.SUPERSPORT
                                    });
                                    useGlobalStore.setState({optionWasSelectedOnce: true});
                                    if (item.cameraPositionData) {
                                        useThreeDReference.getState().threeD?.animateCamera(item.cameraPositionData);
                                    }
                                });
                            }
                            else {
                                useConfigurationStore.setState({configuration: returnConfigurationWithUpdatedPrices(returnConfigurationAfterOptionClick(dataIndex,[optionGroupIndex, index],configuration, {toggle: optionGroup.toggle,deactivateSiblings: !optionGroup.toggle}), basicEquipmentPackage, currentCurrency)});
                                useGlobalStore.setState({optionWasSelectedOnce: true});
                                if (item.cameraPositionData) {
                                    useThreeDReference.getState().threeD?.animateCamera(item.cameraPositionData);
                                }
                            }
                        };

                        let formattedPrice = "";
                        if(!item.active) {
                            formattedPrice = formatPrice(item.displayedPrice, true, true);
                        }
                        
                        switch (configuration[dataIndex].optionGroups[optionGroupIndex].options[index].optionType) {
                            case "thumbnailWithText":
                                return (
                                    <ThumbnailWithText
                                        key={`${optionGroupIndex}//${index}`}
                                        titleHtml={item.displayName[currentLanguage]}
                                        descriptionHtml={item.descriptionHtml?.[currentLanguage]}
                                        imageUrl={item.thumbnailUrl}
                                        active={item.active}
                                        toggle={optionGroup.toggle}
                                        onClick={handleOptionClick}
                                    />
                                );
                            case "textOnly":
                                return (
                                    <TextOnly
                                        key={`${optionGroupIndex}//${index}`}
                                        titleHtml={item.displayName[currentLanguage]}
                                        descriptionHtml={item.descriptionHtml?.[currentLanguage]}
                                        active={item.active}
                                        mandatory={item.mandatory}
                                        toggle={optionGroup.toggle}
                                        subOptions={item.subOptions}
                                        subOptionsToggle={item.subOptionsToggle ? item.subOptionsToggle : false}
                                        optionGroupIndex={optionGroupIndex}
                                        optionIndex={index}
                                        onClick={handleOptionClick}
                                        availableInPackages={item.availableInPackages}
                                        displayedPrice={item.displayedPrice}
                                    />
                                );
                            case "longThumbnailWithText":
                                return (
                                    <LongThumbnailWithText
                                        key={`${optionGroupIndex}//${index}`}
                                        titleHtml={`${item.displayName[currentLanguage]} ${formattedPrice ? "<span>" + formattedPrice + "</span>" : ""}`}
                                        imageUrl={item.thumbnailUrl}
                                        active={item.active}
                                        onClick={handleOptionClick}
                                    />
                                );
                            default:
                                break;
                        }
                    })}
                    {optionGroupContainsMandatoryOptions(optionGroup, configuration) && (
                        <div className={'option-window__mandatory-hint'} dangerouslySetInnerHTML={{__html: `* ${DATA.locale.mandatoryOptions[currentLanguage]}`}}/>
                    )}
                </div>
            );
        }
    );
}

function OptionWindow(props: IOptionWindowProps) {
    const optionWindowRef = useRef<HTMLDivElement>(null);
    const {configuration, basicEquipmentPackage} = useConfigurationStore((state) => state);
    const {currentStepIndex, currentLanguage, currentCurrency} = useGlobalStore((state) => ({
        currentStepIndex: state.currentStepIndex,
        currentLanguage: state.currentLanguage,
        currentCurrency: state.currentCurrency
    }));
    const dataIndex = stepIndexToDataIndex(currentStepIndex);
    const {width: viewportWidth, height: viewportHeight} = useGlobalStore((state) => state.viewport);
    const framerMotionVariant = viewportWidth > viewportHeight ? FRAMER_MOTION_VARIANT_FROM_RIGHT : FRAMER_MOTION_VARIANT_FROM_BOTTOM;
    const [superSportInfoIsOpen, setSuperSportInfoIsOpen] = useState(false);
    const [superSportInfoConfirmHandler, setSuperSportInfoConfirmHandler] = useState<() => void>(() => () => {});
    
    useRightWindowCamOffset(optionWindowRef, viewportWidth, viewportHeight);

    return (
        <motion.div
            variants={framerMotionVariant}
            initial={"initial"}
            animate={"animate"}
            exit={"exit"}
            className={"option-window"}
        >
            <div className={"option-window__inner"}>
                <div ref={optionWindowRef} className={"option-window__innermost"}>
                    <div className={"option-window__text-content"}>
                        <div className={"option-window__text-content-title"}>
                            <h1 dangerouslySetInnerHTML={{__html: configuration[dataIndex].displayName[currentLanguage]}}/>
                        </div>
                        <div className={"option-window__text-content-description"}>
                            <p dangerouslySetInnerHTML={{
                                    __html:
                                        configuration[dataIndex].descriptionHtml[currentLanguage],
                                }}/>
                        </div>
                    </div>
                    <div className={"option-window__option-content"}>
                        <div key={"optionContent" + dataIndex} className={"option-window__option-content-inner"}>
                            {returnOptions(dataIndex, configuration, currentLanguage, basicEquipmentPackage, currentCurrency, setSuperSportInfoIsOpen, setSuperSportInfoConfirmHandler)}
                        </div>
                    </div>
                    <div className={"option-window__button-content"}>
                        <button
                            className={"btn"}
                            onClick={() => {
                                useConfigurationStore.setState((state) => ({
                                        configuration: returnConfigurationWithUpdatedPrices(returnConfigurationWithActivatedMandatoryOptionsAtIndex(stepIndexToDataIndex(currentStepIndex), state.configuration, basicEquipmentPackage), basicEquipmentPackage, currentCurrency)
                                }));
                                useGlobalStore.setState((state) => ({
                                    currentStepIndex: state.currentStepIndex + 1,
                                    highestVisitedStepIndex: state.highestVisitedStepIndex > state.currentStepIndex + 1 ? state.highestVisitedStepIndex : state.currentStepIndex + 1,
                                }));
                            }}
                        >
                            {DATA.locale.continue[currentLanguage]}
                        </button>
                        <button
                            className={"option-window__back-button"}
                            onClick={() => {
                                useGlobalStore.setState((state) => ({
                                    currentStepIndex: state.currentStepIndex - 1,
                                }));
                            }}
                        >
                            <BsArrowLeftShort/>
                            {DATA.locale.back[currentLanguage]}
                        </button>
                    </div>
                </div>
            </div>

            {createPortal(
                <AnimatePresence>
                    {superSportInfoIsOpen && (
                        <Lightbox handleCloseButtonClick={() => setSuperSportInfoIsOpen(false)} title={DATA.locale.switchToSupersportNote[currentLanguage]}>
                            <div className={'lightbox-content-simple'}>
                                <p dangerouslySetInnerHTML={{__html: DATA.locale.switchToSupersportNoteDetail[currentLanguage]}}/>
                                <button className={'btn btn--main'} onClick={() => {setSuperSportInfoIsOpen(false); superSportInfoConfirmHandler();}}>{DATA.locale.yes[currentLanguage]}</button>
                                <button className={'btn'} onClick={() => {setSuperSportInfoIsOpen(false)}}>{DATA.locale.no[currentLanguage]}</button>
                            </div> 
                        </Lightbox>
                    )}
                </AnimatePresence>,
                document.getElementsByTagName('body')[0])
            }
        </motion.div>
    );
}

export default OptionWindow;
