import React, { useEffect, useRef, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { IFormFieldCheckboxProcessed, IFormFieldGenericProcessed, IFormFieldRadioGroupProcessed, IFormFieldSelectProcessed, isLanguageString } from '../../types/formTypes';
import InputText from '../Formfields/InputText';
import { useConfigurationStore, useFormFieldsStore, useGlobalStore, useThreeDReference } from '../../store/store';
import InputTextarea from '../Formfields/InputTextarea';
import InputEmail from '../Formfields/InputEmail';
import RadioGroup, { IRadioGroupChoice } from '../Formfields/RadioGroup';
import Select, { ISelectOption } from '../Formfields/Select';
import InputCheckbox from '../Formfields/InputCheckbox';
import './Contact.scss';
import { BsArrowLeftShort } from 'react-icons/bs';
import DATA from '../../data/data.json';
import {IProcessedConfigurationCategory, ELanguage, ECurrencies, EPackage} from '../../types/types';
import { IFormFieldChangePayloadCheckbox, IFormFieldChangePayloadGeneric, IFormFieldChangePayloadRadioGroup, IFormFieldChangePayloadSelect, isFormFieldChangePayloadCheckbox, isIFormFieldChangePayloadGeneric, isIFormFieldChangePayloadRadioGroup, isIFormFieldChangePayloadSelect } from './ContactTypes';
import SpinnerJimu from '../SpinnerJimu/SpinnerJimu';
import HTMLTester from '../HTMLTester/HTMLTester';
import { motion, AnimatePresence } from 'framer-motion';
import {FRAMER_MOTION_VARIANT_FROM_BOTTOM, FRAMER_MOTION_VARIANT_FROM_RIGHT} from "../../utilities/framerMotionVariants";
import {useRightWindowCamOffset} from "../../hooks/useRightWindowCamOffset";
import "../LightboxContentSummary/LightboxContentSummary.scss";
import {returnSpecialModelEngineGroup, returnConfigurationSummary, returnSummaryTotal} from "../LightboxContentSummary/LightboxContentSummary";

const debugHTML = false;

function returnNewFormFieldsAfterChange(formFieldIndex: number, payload: IFormFieldChangePayloadGeneric | IFormFieldChangePayloadRadioGroup | IFormFieldChangePayloadCheckbox | IFormFieldChangePayloadSelect, currentFormFields: (IFormFieldGenericProcessed | IFormFieldSelectProcessed | IFormFieldRadioGroupProcessed | IFormFieldCheckboxProcessed)[]) {
    const newFormFields = structuredClone(currentFormFields) as (IFormFieldGenericProcessed | IFormFieldSelectProcessed | IFormFieldRadioGroupProcessed | IFormFieldCheckboxProcessed)[];

    if (isIFormFieldChangePayloadGeneric(payload)) {
        (newFormFields[formFieldIndex] as IFormFieldGenericProcessed).value = payload.value;
    }
    else if (isIFormFieldChangePayloadRadioGroup(payload)) {
        (newFormFields[formFieldIndex] as IFormFieldRadioGroupProcessed).choices = (newFormFields[formFieldIndex] as IFormFieldRadioGroupProcessed).choices.map((choice, choiceIndex) => {
            choice.checked = choiceIndex === payload.checkedIndex;
            return choice;
        });
    }
    else if (isIFormFieldChangePayloadSelect(payload)) {
        (newFormFields[formFieldIndex] as IFormFieldSelectProcessed).choices = (newFormFields[formFieldIndex] as IFormFieldSelectProcessed).choices.map(choice => {
            choice.selected = choice.value === payload.selectedValue;
            return choice;
        });
    }
    else if (isFormFieldChangePayloadCheckbox(payload)) {
        (newFormFields[formFieldIndex] as IFormFieldCheckboxProcessed).checked = payload.checked;
    }

    return newFormFields;
}
function buildEmailHtml(formFields: (IFormFieldGenericProcessed | IFormFieldSelectProcessed | IFormFieldRadioGroupProcessed | IFormFieldCheckboxProcessed)[], configuration: IProcessedConfigurationCategory[], currentLanguage: ELanguage, picture: null | string, currentCurrency: ECurrencies, currentPackage: EPackage, isGerman: boolean): string {
    const firstName = (formFields.find(formField => formField.name === 'firstName') as IFormFieldGenericProcessed)?.value;
    const lastName = (formFields.find(formField => formField.name === 'lastName') as IFormFieldGenericProcessed)?.value;
    const address = (formFields.find(formField => formField.name === 'address') as IFormFieldGenericProcessed)?.value;
    const zipCode = (formFields.find(formField => formField.name === 'zipCode') as IFormFieldGenericProcessed)?.value;
    const city = (formFields.find(formField => formField.name === 'city') as IFormFieldGenericProcessed)?.value;
    const country = (formFields.find(formField => formField.name === 'country') as IFormFieldSelectProcessed)?.choices.find(choice => {return choice.selected && choice.value;})?.label[currentLanguage];
    const operatingArea = (formFields.find(formField => formField.name === 'operatingArea') as IFormFieldSelectProcessed)?.choices.find(choice => {return choice.selected && choice.value;})?.label[currentLanguage];
    const eMail = (formFields.find(formField => formField.name === 'email') as IFormFieldGenericProcessed)?.value;
    const phone = (formFields.find(formField => formField.name === 'phone') as IFormFieldGenericProcessed)?.value;
    const message = (formFields.find(formField => formField.name === 'message') as IFormFieldGenericProcessed)?.value;

    // Build text from configuration. Languages are built in.
    let configurationHtml = returnSpecialModelEngineGroup("html", configuration, currentLanguage, currentCurrency, currentPackage) as string + returnConfigurationSummary("html", configuration, currentLanguage, currentCurrency, currentPackage) as string + returnSummaryTotal("html", configuration, currentLanguage, currentCurrency, currentPackage, isGerman);

    //Build rest of text from personal data.
    const html = { en: '', de: '' };
    html[ELanguage.EN] = `
        <h1>Hello${firstName ? ' ' + firstName : ''}${lastName ? ' ' + lastName : ''},</h1>
        <p>thank you for your interest in Sterk!<br/>We've received your configuration for Sterk 31 and will forward the following data to a dealer near you:</p>
        ${firstName || lastName || address || zipCode || city || country || eMail || message ? '<h2>Your personal data:</h2>' : ''}
        <p>${firstName ? firstName : ''}${lastName ? ' ' + lastName : ''}${firstName || lastName ? '<br />' : ''}${address ? address + '<br/>' : ''}${zipCode ? zipCode + ' ' : ''}${city ? ' ' + city : ''}${address || city || zipCode ? '<br/>' : ''}${country ? country : ''}</p>
        ${operatingArea ? '<p><b>Operating area:</b><br/>' + operatingArea + '</p>' : ''}
        ${eMail ? '<p><b>E-Mail:</b><br/>' + eMail + '</p>' : ''}
        ${phone ? '<p><b>Phone:</b><br/>' + phone + '</p>' : ''}
        ${message ? '<p><b>Your message:</b><br/>' + message + '</p>' : ''}
        <h2>Your configuration:</h2>    
        ${picture !== null ? '<img src="' + picture + '" alt="Sterk 31" style="width: 100%; height: auto; max-width: 600px; max-height: 600px; object-fit: contain;"/>' : ''}
        ${configurationHtml}      
        <p>You can revisit your configuration anytime by <a href="${window.location.href}">visiting this link.</a><br/>
        Best regards,<br/>Your Sterk Team</p>
    `;

    html[ELanguage.DE] = `
        <h1>Hallo${firstName ? ' ' + firstName : ''}${lastName ? ' ' + lastName : ''},</h1>
        <p>vielen Dank für Ihr Interesse an Sterk!<br/>Wir haben Ihre Konfiguration für die Sterk31 erhalten und werden die folgenden Daten an einen Händler in Ihrer Nähe weiterleiten:</p>
        ${firstName || lastName || address || zipCode || city || country || eMail || message ? '<h2>Ihre persönlichen Daten:</h2>' : ''}
        <p>${firstName ? firstName : ''}${lastName ? ' ' + lastName : ''}${firstName || lastName ? '<br />' : ''}${address ? address + '<br/>' : ''}${zipCode ? zipCode + ' ' : ''}${city ? ' ' + city : ''}${address || city || zipCode ? '<br/>' : ''}${country ? country : ''}</p>
        ${operatingArea ? '<p><b>Fahrtgebiet:</b><br/>' + operatingArea + '</p>' : ''}
        ${eMail ? '<p><b>E-Mail:</b><br/>' + eMail + '</p>' : ''}
        ${phone ? '<p><b>Telefonnummer:</b><br/>' + phone + '</p>' : ''}
        ${message ? '<p><b>Ihre Nachricht:</b><br/>' + message + '</p>' : ''}
        <h2>Ihre Konfiguration:</h2>
        ${picture !== null ? '<img src="' + picture + '" alt="Sterk 31" style="width: 100%; height: auto; max-width: 600px; max-height: 600px; object-fit: contain;"/>' : ''}    
        ${configurationHtml}        
        <p>Sie können sich Ihre Konfiguration jederzeit wieder anschauen, wenn Sie auf <a href="${window.location.href}">diesen Link klicken.</a><br/>
        Vielen Dank und freundliche Grüße,<br/>Ihr Sterk Team</p>
    `;

    return html[currentLanguage];
}

interface IContactFormProps {}
export default function Contact(props: IContactFormProps) {
    const optionWindowRef = React.useRef<HTMLDivElement>(null);
    const contactWindowState = useGlobalStore(state => state.contactWindowState);
    const emailSendStatusCode = useGlobalStore(state => state.emailSendStatusCode);
    const currentLanguage = useGlobalStore(state => state.currentLanguage);
    const currentFormFields = useFormFieldsStore(state => state.formFields);
    const currentConfiguration = useConfigurationStore(state => state.configuration);
    const basicEquipmentPackage = useConfigurationStore(state => state.basicEquipmentPackage);
    const currentCurrency = useGlobalStore(state => state.currentCurrency);
    const isGerman = useGlobalStore(state => state.isGerman);
    const formRef = useRef<HTMLFormElement>(null);
    const ThreeDInstance = useThreeDReference(state => state.threeD);
    const { width: viewportWidth, height: viewportHeight } = useGlobalStore(state => state.viewport);
    
    useRightWindowCamOffset(optionWindowRef, viewportWidth, viewportHeight);

    const handleFormSend = useCallback(
        (event: React.SyntheticEvent) => {
            event.preventDefault();
            let validity = true;
            if (!formRef.current) return;
            validity = formRef.current.reportValidity();
            if (!validity) return;

            useGlobalStore.setState(state => ({ contactWindowState: 'spinner' }));

            let picture = null;
            if (ThreeDInstance) {
                picture = ThreeDInstance.createRenderingForForm();
            }
            fetch(process.env.PUBLIC_URL + '/api/api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    bodyHtml: buildEmailHtml(currentFormFields, currentConfiguration, currentLanguage, picture, currentCurrency, basicEquipmentPackage, isGerman),
                    subject: DATA.locale.emailSubject[currentLanguage],
                    emailRecipient: (currentFormFields.find(formField => formField.name === 'email') as IFormFieldGenericProcessed)?.value,
                }),
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    /*
                    Fetch will interpret any response as a success, even when it's actually not a success and
                    the status code indicates otherwise. It only goes to catch when experiencing
                    network errors.
                     */
                    //ERROR
                    if (data['@metaData'].statusCode !== undefined) {
                        if (data['@metaData'].statusCode !== 200) {
                            useGlobalStore.setState(state => ({ contactWindowState: 'messageError', emailSendStatusCode: data['@metaData'].statusCode }));
                        } else {
                            useGlobalStore.setState(state => ({ contactWindowState: 'messageSuccess' }));
                        }
                    }
                    //
                    else {
                        useGlobalStore.setState(state => ({ contactWindowState: 'messageError' }));
                    }
                })
                .catch(error => {
                    useGlobalStore.setState(state => ({ contactWindowState: 'messageError' }));
                });
        },
        [currentFormFields, currentConfiguration, currentLanguage]
    );

    function handleFormFieldChange(formFieldIndex: number, payload: IFormFieldChangePayloadGeneric | IFormFieldChangePayloadRadioGroup | IFormFieldChangePayloadCheckbox | IFormFieldChangePayloadSelect) {
        useFormFieldsStore.setState({
            formFields: returnNewFormFieldsAfterChange(formFieldIndex, payload, currentFormFields),
        });
    }
    function renderFields() {
        return currentFormFields.map((formField, formFieldIndex) => {
            if (formField.type === 'text') {
                return <InputText key={formFieldIndex} index={formFieldIndex} label={formField.label[currentLanguage]} required={formField.required} disabled={formField.disabled} name={formField.name} id={formField.id} value={formField.value} error={formField.error} handleFormFieldChange={handleFormFieldChange} />;
            }
            //
            else if (formField.type === 'textarea') {
                return <InputTextarea key={formFieldIndex} index={formFieldIndex} label={formField.label[currentLanguage]} required={formField.required} disabled={formField.disabled} name={formField.name} id={formField.id} value={formField.value} handleFormFieldChange={handleFormFieldChange} />;
            }
            //
            else if (formField.type === 'email') {
                return <InputEmail key={formFieldIndex} index={formFieldIndex} label={formField.label[currentLanguage]} required={formField.required} disabled={formField.disabled} name={formField.name} id={formField.id} value={formField.value} error={formField.error} handleFormFieldChange={handleFormFieldChange} />;
            }
            //
            else if (formField.type === 'radioGroup') {
                const languageFlattenedChoices = formField.choices.map(choice => {
                    const flattenedChoice = { ...choice };
                    (flattenedChoice as unknown as IRadioGroupChoice).label = choice.label[currentLanguage];
                    return flattenedChoice as unknown as IRadioGroupChoice;
                });

                return <RadioGroup id={formFieldIndex.toString()} key={formFieldIndex} index={formFieldIndex} label={formField.label[currentLanguage]} name={formField.name} choices={languageFlattenedChoices} handleFormFieldChange={handleFormFieldChange} />;
            }
            //
            else if (formField.type === 'select') {
                const languageFlattenedChoices = formField.choices.map(choice => {
                    const flattenedChoice = { ...choice };
                    if (isLanguageString(choice.label)) {
                        (flattenedChoice as unknown as ISelectOption).label = choice.label[currentLanguage];
                    }

                    return flattenedChoice as unknown as IRadioGroupChoice;
                });
                languageFlattenedChoices.sort((a, b) => {
                    if (a.label === 'Choose country' || a.label === 'Land auswählen') return -1;
                    if (b.label === 'Choose country' || b.label === 'Land auswählen') return 1;
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
                return <Select key={formFieldIndex} index={formFieldIndex} label={formField.label[currentLanguage]} required={formField.required} options={languageFlattenedChoices} name={formField.name} id={formField.id} handleFormFieldChange={handleFormFieldChange} />;
            }
            //
            else if (formField.type === 'checkbox') {
                return <InputCheckbox key={formFieldIndex} index={formFieldIndex} labelText={formField.label[currentLanguage]} required={formField.required} disabled={formField.disabled} name={formField.name} id={formField.id} checked={formField.checked} handleFormFieldChange={handleFormFieldChange} />;
            }
        });
    }
    function returnSpinner() {
        return (
            <div className='contact__form-content-spinner-overlay'>
                <SpinnerJimu />
            </div>
        );
    }
    function returnContentWithEMail() {
        const formShouldRender = contactWindowState === 'form' || contactWindowState === 'spinner';
        const textContent = {
            en: (
                <React.Fragment>
                    <p>Are you happy with your configuration?</p>
                    <button
                        className={"btn"}
                        onClick={() => {
                            useGlobalStore.setState({ summaryOverlayIsOpen: true });
                        }}
                    >
                        {DATA.locale.showSummary[currentLanguage]}
                    </button>
                    <p>If you fill in and send the following contact form, we will transmit your individual configuration to your nearest, authorised dealer, so that they can advise you concerning your enquiry.</p>
                    <p>A confirmation e-mail will be sent to you.</p>
                </React.Fragment>
            ),
            de: (
                <React.Fragment>
                    <p>Sind Sie mit Ihrer Konfiguration zufrieden?</p>
                    <button
                        className={"btn"}
                        onClick={() => {
                            useGlobalStore.setState({ summaryOverlayIsOpen: true });
                        }}
                    >
                        {DATA.locale.showSummary[currentLanguage]}
                    </button>
                    <p>Wenn Sie das folgende Kontaktformular ausfüllen und absenden, werden wir Ihre individuelle Konfiguration an Ihren nächstgelegenen, autorisierten Händler übermitteln, damit er Sie zu Ihrer Anfrage beraten kann.</p>
                    <p>Wir schicken Ihnen eine Bestätigungs-E-Mail</p>
                </React.Fragment>
            ),
        };
        return (
            <div className='contact__form-content'>
                {contactWindowState === 'spinner' && returnSpinner()}
                <div className={'contact__form-content-inner'}>
                {formShouldRender && (
                        <React.Fragment>
                            <div className={'contact__form-content-description'}>{textContent[currentLanguage]}</div>
                            <form ref={formRef}>{renderFields()}</form>
                            <div className={'contact__form-content-button-container'}>
                                <div className='input-label-wrapper'>
                                    <button type='submit' className='btn' onClick={handleFormSend}>
                                        Send
                                    </button>
                                </div>
                                <button
                                    className={'option-window__back-button'}
                                    onClick={() => {
                                        useGlobalStore.setState(state => ({ currentStepIndex: state.currentStepIndex - 1 }));
                                    }}
                                >
                                    <BsArrowLeftShort />
                                    {DATA.locale.back[currentLanguage]}
                                </button>
                            </div>
                        </React.Fragment>
                    )}
                    {contactWindowState === 'messageSuccess' && (
                        <React.Fragment>
                            <div className={'contact__form-content-description'} dangerouslySetInnerHTML={{ __html: DATA.locale.emailSendSuccess[currentLanguage] }} />
                        </React.Fragment>
                    )}
                    {contactWindowState === 'messageError' && (
                        <React.Fragment>
                            <div className={'contact__form-content-description'}>
                                <div dangerouslySetInnerHTML={{ __html: DATA.locale.emailSendError[currentLanguage] }} />
                                <br />
                                <small>Status Code: {emailSendStatusCode}</small>
                            </div>
                            <div className={'contact__form-content-button-container'}>
                                <button
                                    className={'option-window__back-button'}
                                    onClick={() => {
                                        useGlobalStore.setState(state => ({ contactWindowState: 'form' }));
                                    }}
                                >
                                    <BsArrowLeftShort />
                                    {DATA.locale.tryAgain[currentLanguage]}
                                </button>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }

    const framerMotionVariant = viewportWidth > viewportHeight ? FRAMER_MOTION_VARIANT_FROM_RIGHT : FRAMER_MOTION_VARIANT_FROM_BOTTOM;
    
    return (
        <motion.div
            className='contact'
            initial={'initial'}
            animate={'animate'}
            exit={'exit'}
            variants={framerMotionVariant}
        >
            <div ref={optionWindowRef} className='contact__inner'>
                <div ref={optionWindowRef} className='contact__innermost'>
                    <div className={'contact__text-content'}>
                        <div className={'contact__text-content-title'}>
                            <h1>{DATA.locale.summaryAndContact[currentLanguage]}</h1>
                        </div>
                    </div>
                    {returnContentWithEMail()}
                </div>
            </div>
            {debugHTML && createPortal(<HTMLTester html={buildEmailHtml(currentFormFields, currentConfiguration, currentLanguage, null, currentCurrency, basicEquipmentPackage, isGerman)}/>, document.getElementsByTagName('body')[0])}
        </motion.div>
    );
}
