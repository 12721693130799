import React from 'react';
import './LongThumbnailWithText.scss';

interface ILongThumbnailWithTextProps {
    titleHtml?: string;
    imageUrl?: string;
    active?: boolean;
    onClick?: () => void;
}
function LongThumbnailWithText(props: ILongThumbnailWithTextProps) {
    return (
        <button className={`long-thumbnail-with-text ${props.active ? 'long-thumbnail-with-text--active' : ''}`}>
            <div
                onClick={props.onClick}
                className={`long-thumbnail-with-text__inner`}
            >
                <div className={'long-thumbnail-with-text__image-container'}>
                    <img src={process.env.PUBLIC_URL + props.imageUrl} alt={'placeholder'} />
                </div>
                <div className={`long-thumbnail-with-text__text-container`}>
                    {props.titleHtml && (
                        <h2 className={'long-thumbnail-with-text__title'} dangerouslySetInnerHTML={{ __html: props.titleHtml }} />
                    )}
                </div>
            </div>
        </button>
    );
}
export default LongThumbnailWithText;
