import React, { useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import './Hotspots.scss';
import { useConfigurationStore, useGlobalStore, useSceneActionHotspotsStore } from '../../store/store';
import { getNestedProperty, getObjectByName, returnNewSceneActionHotspotsAfterHotspotClick, stepIndexToDataIndex } from '../../utilities/utilities';
import usePrevious from '../../utilities/usePrevious';

interface IHotspotsProps {}

const Hotspots = forwardRef<any, IHotspotsProps>((props, ref) => {
    const hotspotsData = useSceneActionHotspotsStore(state => state.sceneActionHotspots);
    const configuration = useConfigurationStore(state => state.configuration);
    const previousConfiguration = usePrevious(configuration);
    const currentLanguage = useGlobalStore(state => state.currentLanguage);
    const currentStepIndex = useGlobalStore(state => state.currentStepIndex);
    const hotspotRefs = useRef<Array<HTMLLIElement | null>>([]);

    useImperativeHandle(ref, () => {
        return hotspotRefs.current;
    });

    //Open rear cabin when rear cabin furniture is selected
    useEffect(() => {
        if (!previousConfiguration) return;
        const prevRcFurniture = getObjectByName(previousConfiguration, 'rearCabinFurnished');
        const rcFurniture = getObjectByName(configuration, 'rearCabinFurnished');

        if (prevRcFurniture === null) return;
        if (rcFurniture === null) return;

        if (prevRcFurniture.active !== rcFurniture.active && rcFurniture.active) {
            const rearCabinHotspotIndex = hotspotsData.findIndex((hotspot: any) => hotspot.name === 'rearCabin');
            useSceneActionHotspotsStore.setState({
                sceneActionHotspots: returnNewSceneActionHotspotsAfterHotspotClick(rearCabinHotspotIndex, hotspotsData, { activate: true }),
            });
        }
    }, [configuration, previousConfiguration]);

    //Open benches when either fridge is selected
    useEffect(() => {
        if (!previousConfiguration) return;
        const prevFridge1 = getObjectByName(previousConfiguration, 'fridge1');
        const fridge1 = getObjectByName(configuration, 'fridge1');
        const prevFridge2 = getObjectByName(previousConfiguration, 'fridge2');
        const fridge2 = getObjectByName(configuration, 'fridge2');

        if (prevFridge1 === null) return;
        if (fridge1 === null) return;
        if (prevFridge2 === null) return;
        if (fridge2 === null) return;

        if ((prevFridge1.active !== fridge1.active && fridge1.active) || (prevFridge2.active !== fridge2.active && fridge2.active)) {
            const benchesHotspotIndex = hotspotsData.findIndex((hotspot: any) => hotspot.name === 'benches');
            useSceneActionHotspotsStore.setState({
                sceneActionHotspots: returnNewSceneActionHotspotsAfterHotspotClick(benchesHotspotIndex, hotspotsData, { activate: true }),
            });
        }
    }, [configuration, previousConfiguration]);

    //Close benches and rc door when one step ahead or behind step "model
    useEffect(() => {
        if (configuration[stepIndexToDataIndex(currentStepIndex + 1)].name === 'model' || configuration[stepIndexToDataIndex(currentStepIndex - 1)].name === 'model') {
            const benchesHotspotIndex = hotspotsData.findIndex((hotspot: any) => hotspot.name === 'benches');
            const rcDoorHotspotIndex = hotspotsData.findIndex((hotspot: any) => hotspot.name === 'rearCabin');

            const closedBenchesConfiguration = returnNewSceneActionHotspotsAfterHotspotClick(benchesHotspotIndex, hotspotsData, { deactivate: true });

            useSceneActionHotspotsStore.setState({
                sceneActionHotspots: returnNewSceneActionHotspotsAfterHotspotClick(rcDoorHotspotIndex, closedBenchesConfiguration, { deactivate: true }),
            });
        }
    }, [currentStepIndex]);

    //Open bathroom when bathroom step is reached, close it one step ahead and behind
    useEffect(() => {
        if (configuration[stepIndexToDataIndex(currentStepIndex)].name === 'bathroom') {
            const bathroomHotspotIndex = hotspotsData.findIndex((hotspot: any) => hotspot.name === 'bathroom');
            useSceneActionHotspotsStore.setState({
                sceneActionHotspots: returnNewSceneActionHotspotsAfterHotspotClick(bathroomHotspotIndex, hotspotsData, { activate: true }),
            });
        }
        //
        else if (configuration[stepIndexToDataIndex(currentStepIndex + 1)].name === 'bathroom' || configuration[stepIndexToDataIndex(currentStepIndex - 1)].name === 'bathroom') {
            const bathroomHotspotIndex = hotspotsData.findIndex((hotspot: any) => hotspot.name === 'bathroom');
            useSceneActionHotspotsStore.setState({
                sceneActionHotspots: returnNewSceneActionHotspotsAfterHotspotClick(bathroomHotspotIndex, hotspotsData, { deactivate: true }),
            });
        }
    }, [currentStepIndex]);

    return (
        <ul className={'hotspots'}>
            {hotspotsData.map((hotspot: any, hotspotIndex: number) => {
                let displayName = hotspot.active ? hotspot.displayNameActive[currentLanguage] : hotspot.displayNameInactive[currentLanguage];
                let visibilityClassName = hotspot.visibleAtStepIndexes.includes(currentStepIndex) ? 'hotspots__item--visible' : 'hotspots__item--hidden';

                //Exceptions for hotspot visibility
                //Show hotspots "Open rear cabin" and "Open benches" only when rear cabin option is selected"
                if ((hotspot.name === 'rearCabin' && hotspot.visibleAtStepIndexes.includes(currentStepIndex)) || (hotspot.name === 'benches' && hotspot.visibleAtStepIndexes.includes(currentStepIndex))) {
                    if (configuration.find(x => x.name === 'model')?.optionGroups[0].options.find(x => x.active)?.name === 'rc') {
                        visibilityClassName = 'hotspots__item--visible';
                    } else {
                        visibilityClassName = 'hotspots__item--hidden';
                    }
                }

                return (
                    <li
                        className={`hotspots__item ${visibilityClassName}`}
                        key={hotspotIndex}
                        ref={el => {
                            return (hotspotRefs.current[hotspotIndex] = el);
                        }}
                        onClick={() => {
                            useSceneActionHotspotsStore.setState({
                                sceneActionHotspots: returnNewSceneActionHotspotsAfterHotspotClick(hotspotIndex, hotspotsData),
                            });
                        }}
                    >
                        <div className={'hotspots__item-inner'}>
                            <div className={'hotspots__item-innermost'}>{displayName}</div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
});

export default Hotspots;
