import React, { useEffect } from 'react';
import './Progress.scss';
import { useConfigurationStore, useGlobalStore } from '../../store/store';
import { returnRomanNumeral } from '../../utilities/utilities';
import { STEPS_AFTER_CONFIGURATION, STEPS_BEFORE_CONFIGURATION } from '../../utilities/globals';
import DATA from '../../data/data.json';

interface IProgressProps {}

function Progress(props: IProgressProps) {
    const configuration = useConfigurationStore(state => state.configuration);
    const { currentStepIndex, currentLanguage, highestVisitedStepIndex } = useGlobalStore(state => ({
        currentStepIndex: state.currentStepIndex,
        currentLanguage: state.currentLanguage,
        optionWasSelectedOnce: state.optionWasSelectedOnce,
        highestVisitedStepIndex: state.highestVisitedStepIndex,
    }));

    return (
        <div className={'progress'}>
            <ul className={'progress__menu'}>
                <li
                    onClick={() => {useGlobalStore.setState({currentStepIndex: 0});}}
                    key={'intro'}
                    className={`progress__menu-item ${currentStepIndex > 0 ? 'progress__menu-item--completed' : ''} ${currentStepIndex === 0 ? 'progress__menu-item--active' : ''}`}
                >
                    <span className={'progress__menu-item-label'}>
                        <span className={'progress__menu-item-label-roman'}>{`*`}</span>
                        <span className={'progress__menu-item-label-text'}>{DATA.locale.welcome[currentLanguage]}</span>
                    </span>
                </li>
                <li
                    onClick={() => {useGlobalStore.setState({currentStepIndex: 1});}}
                    key={'sportSupersportIntro'}
                    className={`progress__menu-item
                ${currentStepIndex > 1 || highestVisitedStepIndex >= 1 ? 'progress__menu-item--completed' : ''}
                ${currentStepIndex === 1 ? 'progress__menu-item--active' : ''} 
                ${currentStepIndex < 1 && highestVisitedStepIndex < 1 ? 'progress__menu-item--future' : ''}`} 
                >
                    <span className={'progress__menu-item-label'}>
                        <span className={'progress__menu-item-label-roman'}>{returnRomanNumeral(0)}</span>
                        <span className={'progress__menu-item-label-text'}>{DATA.locale.basicEquipment[currentLanguage]}</span>
                    </span>
                </li>
                {configuration.map((item, index) => {
                    return (
                        <li
                            onClick={() => {useGlobalStore.setState({currentStepIndex: index + STEPS_BEFORE_CONFIGURATION});}}
                            key={index + STEPS_BEFORE_CONFIGURATION}
                            className={`progress__menu-item 
                        ${currentStepIndex > index + STEPS_BEFORE_CONFIGURATION || highestVisitedStepIndex >= index + STEPS_BEFORE_CONFIGURATION ? 'progress__menu-item--completed' : ''} 
                        ${currentStepIndex === index + STEPS_BEFORE_CONFIGURATION ? 'progress__menu-item--active' : ''} 
                        ${currentStepIndex < index + STEPS_BEFORE_CONFIGURATION && highestVisitedStepIndex < index + STEPS_BEFORE_CONFIGURATION ? 'progress__menu-item--future' : ''}`}
                        >
                            <span className={'progress__menu-item-label'}>
                                <span className={'progress__menu-item-label-roman'}>{returnRomanNumeral(STEPS_BEFORE_CONFIGURATION - 1 + index)}</span>
                                <span className={'progress__menu-item-label-text'}>{item.displayName[currentLanguage]}</span>
                            </span>
                        </li>
                    );
                })}
                <li
                    onClick={() => {useGlobalStore.setState({currentStepIndex: STEPS_BEFORE_CONFIGURATION  + configuration.length });}}
                    key={'contact'}
                    className={`progress__menu-item               
                    ${highestVisitedStepIndex >= configuration.length + STEPS_BEFORE_CONFIGURATION ? 'progress__menu-item--completed' : ''} 
                    ${currentStepIndex === configuration.length + STEPS_BEFORE_CONFIGURATION ? 'progress__menu-item--active' : ''}
                    ${currentStepIndex < (configuration.length + STEPS_BEFORE_CONFIGURATION) && highestVisitedStepIndex < (configuration.length + STEPS_BEFORE_CONFIGURATION) ? 'progress__menu-item--future' : ''}`}
                >
                    <span className={'progress__menu-item-label'}>
                        <span className={'progress__menu-item-label-roman'}>{returnRomanNumeral(STEPS_BEFORE_CONFIGURATION + configuration.length - 1)}</span>
                        <span className={'progress__menu-item-label-text'}>{DATA.locale.summary[currentLanguage]}</span>
                    </span>
                </li>
            </ul>
        </div>
    );
}

export default Progress;
