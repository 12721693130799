export const FRAMER_MOTION_VARIANT_TEXT = {
    initial: {
        opacity: 0,
        x: -40,
    },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            type: 'spring',
            duration: 0.8,
            bounce: 0.3,
        },
    },
    exit: {
        opacity: 0,
        x: 40,
        transition: {
            type: 'spring',
            duration: 0.3,
            bounce: 0,
        },
    },
};

export const FRAMER_MOTION_VARIANT_FROM_BOTTOM = {
    initial: {
        opacity: 0,
        y: 200,
        z: 100,
    },
    animate: {
        opacity: 1,
        y: 0,
        z: 0,
        transition: {
            type: 'spring',
            duration: 1.2,
            bounce: 0.3,
        },
    },
    exit: {
        opacity: 0,
        y: 200,
        z: 100,
        transition: {
            type: 'spring',
            duration: 0.3,
            bounce: 0,
        },
    },
};

export const FRAMER_MOTION_VARIANT_FROM_RIGHT = {
    initial: {
        opacity: 0,
        x: 1000,
    },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            type: 'spring',
            duration: 1.2,
            bounce: 0.3,
        },
    },
    exit: {
        opacity: 0,
        x: 1000,
        transition: {
            type: 'spring',
            duration: 0.3,
            bounce: 0,
        },
    },
};

export const FRAMER_MOTION_VARIANT_SPEECHTEXT = {
    initial: {
        opacity: 0,
        y: -10,
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'spring',
            duration: 0.8,
            bounce: 0.3,
        },
    },
    exit: {
        opacity: 0,
        y: 10,
        transition: {
            type: 'spring',
            duration: 0.3,
            bounce: 0,
        },
    },
};

export const FRAMER_MOTION_VARIANT_CONTAINER = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1,
        },
    },
    exit: {
        opacity: 0,
    },
};

export const FRAMER_MOTION_VARIANT_LIGHTBOX = {
    initial: {
        y: -80,
        rotate: 1,
        scale:0.9,
        opacity:0
    },
    animate: {
        y: 0,
        rotate: 0,
        scale:1,
        opacity:1,
        transition: {
            type: 'spring',
            damping: 14,
            mass: 1,
            stiffness: 100,
            staggerChildren: 0.1,
            staggerDirection: 1,
        },
    },
    exit: {
        y: -20,
        rotate: 1,
        scale:0.95,
        opacity:0,
        transition: {
            damping: 10,
            mass: 0.6,
            stiffness: 100,
            when: 'afterChildren',
            staggerChildren: 0.02,
            staggerDirection: -1,
        },
    },
};

export const FRAMER_MOTION_VARIANT_HEIGHT = {
    initial: {
        height: 0,
    },
    animate: {
        height: 'auto',
    },
    exit: {
        height: 0,
    },
};

export const FRAMER_MOTION_VARIANT_SCALE_UP = {
    initial: {
        opacity: 0,
        scale: 0,
    },
    animate: {
        opacity: 1,
        scale: 1,
        transition: {
            type: 'spring',
            bounce: 0.3,
            duration: 0.6,
        },
    },
    exit: {
        opacity: 0,
        scale: 0,
        transition: {
            type: 'spring',
            bounce: 0.3,
            duration: 0.6,
        },
    },
};

export const FRAMER_MOTION_3D_CONTAINER = {
    hidden: {
        y: -0.01,
        transition: {
            staggerChildren: 0.02,
            staggerDirection: -1,
            when: 'afterChildren',
        },
    },
    visible: {
        y: 0,
        transition: {
            delay: 0.25,
            staggerChildren: 0.04,
            when: 'beforeChildren',
        },
    },
};

export const FRAMER_MOTION_3D_MESH_SCALE = {
    hidden: {
        scale: 0,
        transition: {
            duration: 0.15,
            bounce: 0,
            type: 'spring',
        },
    },
    visible: {
        scale: 1,
        transition: {
            duration: 0.8,
            bounce: 0.5,
            type: 'spring',
        },
    },
};

export const FRAMER_MOTION_3D_MESH_FROM_TOP = {
    hidden: {
        scale: 0.8,
        y: 7,
        transition: {
            type: 'spring',
            duration: 0.2,
            bounce: 0,
        },
    },
    visible: {
        scale: 1,
        y: 0,
        transition: {
            duration: 0.8,
            bounce: 0.1,
            type: 'spring',
        },
    },
};

export const FRAMER_MOTION_3D_MESH_FROM_FRONT = {
    hidden: {
        scale: 0,
        x: 5,
        transition: {
            duration: 0.1,
            bounce: 0,
            type: 'spring',
        },
    },
    visible: {
        scale: 1,
        x: 0,
        transition: {
            duration: 0.5,
            bounce: 0,
            type: 'spring',
        },
    },
};
