export interface IFormFieldChangePayloadGeneric {
    type: 'text' | 'textarea' | 'email';
    value: string;
}
export interface IFormFieldChangePayloadSelect {
    type: 'text' | 'textarea' | 'email' | 'select';
    selectedValue: string;
}
export interface IFormFieldChangePayloadRadioGroup {
    type: 'radioGroup';
    checkedIndex: number;
}
export interface IFormFieldChangePayloadCheckbox {
    type: 'checkbox';
    checked: boolean;
}
export function isIFormFieldChangePayloadGeneric(payload: any): payload is IFormFieldChangePayloadGeneric {
    return payload.type === 'text' || payload.type === 'textarea' || payload.type === 'email';
}
export function isIFormFieldChangePayloadSelect(payload: any): payload is IFormFieldChangePayloadSelect {
    return payload.type === 'select';
}
export function isIFormFieldChangePayloadRadioGroup(payload: any): payload is IFormFieldChangePayloadRadioGroup {
    return payload.type === 'radioGroup';
}
export function isFormFieldChangePayloadCheckbox(payload: any): payload is IFormFieldChangePayloadCheckbox {
    return payload.type === 'checkbox';
}
