import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";
import {GLTF, GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader";
import {PMREMGenerator} from "three/src/extras/PMREMGenerator";
import {gsap} from "gsap";
import CustomEase from "gsap/dist/CustomEase";
import { checkCondition, getNestedProperty, hexToRgb, mapRange, removeExtension, returnNewSceneActionHotspotsAfterAnimationEnd, returnNewSceneActionHotspotsAfterAnimationStart, stepIndexToDataIndex} from "../../utilities/utilities";
// @ts-ignore
import ralToHex from "ral-to-hex";
import ShaderStudioRampVertex from "./Shaders/studioRamp_vertex.glsl";
import ShaderStudioRampFragment from "./Shaders/studioRamp_fragment.glsl";
import DATA from "../../data/data.json";
import {GUI} from "dat.gui";

import {ACESFilmicToneMapping,AxesHelper,Clock,Color,DirectionalLight,DirectionalLightHelper,GridHelper,Group,BoxGeometry,Mesh,MeshBasicMaterial,Face,LoadingManager,MeshStandardMaterial,Object3D,PerspectiveCamera,RepeatWrapping,Scene,ShaderMaterial,SRGBColorSpace,Texture,TextureLoader,Vector2,Vector3,WebGLRenderer,} from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {useConfigurationStore,useGlobalStore,useSceneActionHotspotsStore} from "../../store/store";
import {IConfigurationCategory, ICameraPosition, ILoadingStats, isISceneActionBackground, isISceneActionColor, isISceneActionMaterial, isISceneActionTexture, isISceneActionVisibility, ITextureCache, IThreeDConfig, ThreeDOptions, IConditionsAndSceneActions, ICameraPositionAllViewports, IProcessedConfigurationCategory, IProcessedSceneActionHotspot, isISceneActionParameterAnimation, ISceneActionVisibility, ISceneActionColor, ISceneActionTexture, ISceneActionBackground, ISceneActionMaterial, ISceneActionPosition, ISceneActionParameterAnimation, IProcessedConfigurationOption, TSceneAction, EPackage} from "../../types/types";
import {STEPS_AFTER_CONFIGURATION,STEPS_BEFORE_CONFIGURATION} from "../../utilities/globals";

export class ThreeD {
    private static readonly noop = () => {
    };

    private config: IThreeDConfig;

    readonly canvas: HTMLCanvasElement;
    readonly hotspotRefs: any;
    private userCamera: any;
    private hotspots: ({
        distanceFromCamera: null | number;
        zIndex: null | number;
        object3D: Object3D;
    } | null)[];
    private materialList: Array<MeshStandardMaterial>;
    private textures: ITextureCache = {};
    private loadingStats: ILoadingStats;
    private resizeTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
    });
    private consumerHandleResize: Function;
    private consumerHandleAfterLoad: Function;
    private consumerHandleFrameRender: Function;
    private consumerHandleLoadingProgress: Function;
    private animateRequested: boolean;
    private activeCameraTween: undefined | gsap.core.Tween;
    private activeBackgroundTween: undefined | gsap.core.Tween;
    private raf = 0;
    private defaultCameraPosition: ICameraPosition = {
        target: {
            x: 0.6038462893790547,
            y: 0.8506045227572985,
            z: 0.16077793444216162,
        },
        camera: {
            x: 13.588678220346285,
            y: 5.302335494669178,
            z: -9.767100699171657,
        },
    };
    private DRACOLoader = new DRACOLoader();
    private loadingManager = new LoadingManager();
    private textureLoader = new TextureLoader();
    private GLTFLoader = new GLTFLoader(this.loadingManager);
    private RGBELoader = new RGBELoader(this.loadingManager);
    private clock = new Clock();
    private camTargetHelper = new Object3D();

    /* Have to be type asserted because can't be initialized in constructor since they're all dependent on values that are only present after mount */
    private envTexture!: Texture;
    private scene!: Scene;
    private controls!: OrbitControls;
    private controlsTarget!: Vector3;
    private studioRampMaterial!: ShaderMaterial;
    private studioRampTexture!: Texture;
    private renderer!: WebGLRenderer;
    private spotlight!: DirectionalLight;
    private spotlightTarget!: Object3D;
    private spotlightHelper!: DirectionalLightHelper;

    /******************************************************************
     * Constructor
     *****************************************************************/
    constructor(options: ThreeDOptions) {
        gsap.registerPlugin(CustomEase);

        this.config = {
            frameRate: 60,
            maxResolution: 3840,
            debug: false,
            containObjectInViewParams: {
                portraitAspectRatio: 0.56,
                portraitZoom: 0.4,
                landscapeAspectRatio: 1.778,
                landscapeZoom: 1.0,
            },
            logCamPosition: false,
            showGridHelper: false,
            showLightHelpers: false,
            showCamTarget: false,
            startCameraPosition: {
                landscape: {
                    target: {
                        x: 0.6425861974649302,
                        y: 1.5357196986005646,
                        z: -0.03441814876197338,
                    },
                    camera: {
                        x: 0.7694785935213694,
                        y: 6.345796088935994,
                        z: -15.488299268377894,
                    },
                },
            },
        };

        this.hotspotRefs = options.hotspotRefs ? options.hotspotRefs : [];
        this.canvas = options.canvas;
        this.materialList = [];

        this.hotspots = [];
        this.loadingStats = {
            gltfLoaded: 0,
            gltfTotal: 10729988,
            rgbeLoaded: 0,
            rgbeTotal: 3489839,
            rgbeWasLoaded: false,
            gltfWasLoaded: false,
        };
        this.animateRequested = false;
        this.consumerHandleResize = ThreeD.noop;
        this.consumerHandleAfterLoad = ThreeD.noop;
        this.consumerHandleFrameRender = ThreeD.noop;
        this.consumerHandleLoadingProgress = ThreeD.noop;

        this.animate = this.animate.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleAnyObjectHasLoaded = this.handleAnyObjectHasLoaded.bind(this);
        this.handleGLTFLoaded = this.handleGLTFLoaded.bind(this);
        this.handleRGBELoaded = this.handleRGBELoaded.bind(this);
        this.handleGLTFLoadingProgress = this.handleGLTFLoadingProgress.bind(this);
        this.handleRGBELoadingProgress = this.handleRGBELoadingProgress.bind(this);
    }

    /******************************************************************
     * Setters
     *****************************************************************/
    set onResize(callback: Function) {
        this.consumerHandleResize = callback;
    }

    set onReady(callback: Function) {
        this.consumerHandleAfterLoad = callback;
    }

    set onFrameRender(callback: Function) {
        this.consumerHandleFrameRender = callback;
    }

    set onLoadingProgress(callback: Function) {
        this.consumerHandleLoadingProgress = callback;
    }

    /******************************************************************
     * Public Methods
     *****************************************************************/
    /**
     * Init function. Sets up all THREE objects and triggers loading of 3D assets.
     */
    public init(): void {
        this.log("Initializing");
        this.DRACOLoader.setDecoderPath(process.env.PUBLIC_URL + "/prebuiltJavascript/");
        this.textureLoader.setPath(process.env.PUBLIC_URL + "/textures/");
        this.RGBELoader.setPath(process.env.PUBLIC_URL + "/textures/");
        this.GLTFLoader.setDRACOLoader(this.DRACOLoader);
        this.userCamera = new PerspectiveCamera(23,this.canvas.clientWidth / this.canvas.clientHeight,0.1,400);
        this.scene = new Scene();

        this.studioRampMaterial = new ShaderMaterial({
            uniforms: {
                hdrTexture: {value: null},
                gradientColor1: {value: new Color(1.0, 1.0, 1.0)},
                gradientColor2: {value: new Color(1.0, 1.0, 1.0)},
                toneMappingExposure2: {value: 1.1},
                minY: {value: -2.2},
                maxY: {value: 2.02},
            },
            vertexShader: ShaderStudioRampVertex,
            fragmentShader: ShaderStudioRampFragment,
            toneMapped: true,
            dithering: true,
        });

        this.renderer = new WebGLRenderer({
            powerPreference: "high-performance",
            antialias: true,
            alpha: true,
            canvas: this.canvas,
        });
        this.renderer.toneMapping = ACESFilmicToneMapping;
        this.renderer.toneMappingExposure = 1.0;
        this.renderer.shadowMap.enabled = true;
        this.renderer.outputColorSpace = SRGBColorSpace;

        let width = Math.floor(this.canvas.clientWidth * window.devicePixelRatio);
        let height = Math.floor(this.canvas.clientHeight * window.devicePixelRatio);
        let ratio = height / width;
        if (width > this.config.maxResolution) {
            width = this.config.maxResolution;
            height = width * ratio;
        }
        if (height > this.config.maxResolution) {
            height = this.config.maxResolution;
            width = height / ratio;
        }
        this.renderer.setSize(width, height, false);

        this.controls = new OrbitControls(this.userCamera, this.canvas);
        this.controls.target.set(this.config.startCameraPosition.landscape.target.x, this.config.startCameraPosition.landscape.target.y, this.config.startCameraPosition.landscape.target.z);
        this.controls.minDistance = 1.5;
        this.controls.maxDistance = 200;
        this.controls.enablePan = true;
        this.controls.enableZoom = true;
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.1;
        this.controls.enabled = true;
        this.controls.maxPolarAngle = Math.PI / 2;
        this.controls.rotateSpeed = 0.5;
        this.userCamera.position.x = this.config.startCameraPosition.landscape.camera.x;
        this.userCamera.position.y = this.config.startCameraPosition.landscape.camera.y;
        this.userCamera.position.z = this.config.startCameraPosition.landscape.camera.z;

        this.controls.addEventListener("change", () => {
            this.requestAnimateIfNotRequested();
        });

        if (this.config.logCamPosition || this.config.showCamTarget) {
            this.controls.addEventListener("change", () => {
                if (this.config.logCamPosition) {
                    console.log(
                        `"target":{\n    "x": ${this.controls.target.x},\n    "y": ${this.controls.target.y},\n    "z": ${this.controls.target.z}\n},\n "camera": {\n    "x": ${this.userCamera.position.x},\n    "y": ${this.userCamera.position.y},\n    "z": ${this.userCamera.position.z}\n}`
                    );
                }
                if (this.config.showCamTarget) {
                    this.camTargetHelper.position.set(
                        this.controls.target.x,
                        this.controls.target.y,
                        this.controls.target.z
                    );
                }
            });
        }
        //Grid & Axis Helper
        if (this.config.showGridHelper) {
            this.scene.add(new GridHelper(12, 120));
            this.scene.add(new AxesHelper(12));
        }

        //Spotlight
        this.spotlightTarget = new Object3D();
        this.spotlightTarget.position.set(0, 0, 0);
        this.scene.add(this.spotlightTarget);
        this.spotlight = new DirectionalLight("rgb(255,254,248)", 1.1);
        this.spotlight.target = this.spotlightTarget;
        this.spotlight.castShadow = true;
        this.spotlight.position.set(10, 28, -8);
        this.spotlight.shadow.radius = 4;
        this.spotlight.shadow.mapSize.width = 2048;
        this.spotlight.shadow.mapSize.height = 2048;
        this.spotlight.shadow.camera.near = 0.5;
        this.spotlight.shadow.camera.far = 1000;
        this.spotlight.shadow.camera.bottom = -7;
        this.spotlight.shadow.camera.top = 7;
        this.spotlight.shadow.camera.right = 7;
        this.spotlight.shadow.camera.left = -7;
        this.spotlight.shadow.bias = -0.0001;

        this.spotlightHelper = new DirectionalLightHelper(this.spotlight);
        if (this.config.showLightHelpers) {
            this.scene.add(this.spotlightHelper);
        }
        this.scene.add(this.spotlight);

        this.loadObjects();

        useGlobalStore.subscribe((state) => state.viewport, (viewport, oldViewport) => {
                if (viewport.width === oldViewport.width && viewport.height === oldViewport.height) return;
                this.handleResize();
            });
        useGlobalStore.subscribe((state) => state.currentStepIndex, (currentStepIndex, previousStepIndex) => {
                if (currentStepIndex === previousStepIndex) return;
                this.handleStepIndexUpdate(currentStepIndex);
            });
        useGlobalStore.subscribe((state) => state.camOffset, (camOffset, previousCamOffset) => {
                if (
                    camOffset.x === previousCamOffset.x &&
                    camOffset.y === previousCamOffset.y
                )
                    return;
                this.handleCamOffsetUpdate(camOffset, previousCamOffset);
            });
        useConfigurationStore.subscribe((state, prevState) => {
            this.performSceneActions(DATA.packageSceneActions[state.basicEquipmentPackage] as TSceneAction[])
            this.synchronizeSceneWithState(state.configuration, state.basicEquipmentPackage);
        });
        useSceneActionHotspotsStore.getState().sceneActionHotspots.forEach(() => {
            this.hotspots.push(null);
        });
        useSceneActionHotspotsStore.subscribe((state, prevState) => {
            const configuration = useConfigurationStore.getState().configuration;
            this.synchronizeSceneWithHotspots(state.sceneActionHotspots, configuration);
        });
    }

    public animateCamera(payload: ICameraPositionAllViewports,duration?: number) {
        const viewportDependentCameraData = this.returnViewportDependentCameraPosition(payload);
        let tempObj = {
            targetX: this.controls.target.x,
            targetY: this.controls.target.y,
            targetZ: this.controls.target.z,
            cameraX: this.userCamera.position.x,
            cameraY: this.userCamera.position.y,
            cameraZ: this.userCamera.position.z,
        };
        if (this.activeCameraTween) {
            this.activeCameraTween.kill();
        }
        this.activeCameraTween = gsap.to(tempObj, {
            targetX: viewportDependentCameraData.target.x,
            targetY: viewportDependentCameraData.target.y,
            targetZ: viewportDependentCameraData.target.z,
            cameraX: viewportDependentCameraData.camera.x,
            cameraY: viewportDependentCameraData.camera.y,
            cameraZ: viewportDependentCameraData.camera.z,
            duration: duration ? duration : 1.5,
            ease: CustomEase.create("custom", "M0,0,C0.28,0.02,0.194,1,1,1"),
            onUpdate: () => {
                this.controls.target.set(
                    tempObj.targetX,
                    tempObj.targetY,
                    tempObj.targetZ
                );
                this.userCamera.position.set(
                    tempObj.cameraX,
                    tempObj.cameraY,
                    tempObj.cameraZ
                );
                this.requestAnimateIfNotRequested();
            },
        });

        this.requestAnimateIfNotRequested();
    }

    public centerCamera() {
        const currentStepIndex = useGlobalStore.getState().currentStepIndex;
        const dataIndex = stepIndexToDataIndex(currentStepIndex);
        this.animateCamera(
            (DATA.configurationCategories as IConfigurationCategory[])[dataIndex]
                .cameraPositionData
        );
    }

    public createRenderingForForm() {
        let picture;
        let currentCameraPosition = {
            x: this.userCamera.position.x,
            y: this.userCamera.position.y,
            z: this.userCamera.position.z,
        };
        let currentTargetPosition = {
            x: this.controls.target.x,
            y: this.controls.target.y,
            z: this.controls.target.z,
        };

        let currentCameraViewOffsetX = this.userCamera.view
            ? this.userCamera.view.offsetX
            : 0;
        let currentCameraViewOffsetY = this.userCamera.view
            ? this.userCamera.view.offsetY
            : 0;

        cancelAnimationFrame(this.raf);
        this.animateRequested = false;

        const oldCanvasWidth = this.canvas.clientWidth;
        const oldCanvasHeight = this.canvas.clientHeight;
        const oldResolution = new Vector2();
        this.renderer.getSize(oldResolution);
        const oldZoom = this.userCamera.zoom;
        const newWidth = 1280;
        const newHeight = 720;
        this.renderer.setSize(newWidth, newHeight, false);
        this.canvas.style.width = newWidth + "px";
        this.canvas.style.height = newHeight + "px";

        this.userCamera.setViewOffset(
            newWidth,
            newHeight,
            0,
            0,
            newWidth,
            newHeight
        );
        this.userCamera.zoom = 1;
        this.userCamera.updateProjectionMatrix();

        this.userCamera.position.set(
            this.defaultCameraPosition.camera.x,
            this.defaultCameraPosition.camera.y,
            this.defaultCameraPosition.camera.z
        );
        this.controls.target.set(
            this.defaultCameraPosition.target.x,
            this.defaultCameraPosition.target.y,
            this.defaultCameraPosition.target.z
        );
        this.controls.update();

        this.renderer.render(this.scene, this.userCamera);
        picture = this.canvas.toDataURL("image/jpeg", 0.7);

        //Debug Screenshot
        /*
            const win = window.open();
            if (win) {
                win.document.write('<iframe src="' + picture + '" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>');
            }
            
             */

        this.renderer.setSize(oldResolution.x, oldResolution.y);
        this.canvas.style.width = "";
        this.canvas.style.height = "";

        this.userCamera.setViewOffset(
            oldCanvasWidth,
            oldCanvasHeight,
            currentCameraViewOffsetX,
            currentCameraViewOffsetY,
            oldCanvasWidth,
            oldCanvasHeight
        );
        this.userCamera.zoom = oldZoom;
        this.userCamera.updateProjectionMatrix();

        this.userCamera.position.set(
            currentCameraPosition.x,
            currentCameraPosition.y,
            currentCameraPosition.z
        );
        this.controls.target.set(
            currentTargetPosition.x,
            currentTargetPosition.y,
            currentTargetPosition.z
        );
        this.controls.update();
        this.requestAnimateIfNotRequested();

        return picture;
    }

    public createHighlightAnimation(meshNames: string[]) {
    }

    /******************************************************************
     * Private Methods
     *****************************************************************/
    /**
     * Based on synchronizeSceneWithState but using the sceneActionsHotspot-State, which tries to implement similar rules
     * like the old synchronizeSceneWithState function but with animations.
     * Might be merged with synchronizeSceneWithState in the future.
     * @param sceneActionHotspots
     * @param configuration
     * @private
     */
    private synchronizeSceneWithHotspots(sceneActionHotspots: IProcessedSceneActionHotspot[], configuration: IProcessedConfigurationCategory[]) {
        sceneActionHotspots.forEach((hotspot, hotspotIndex) => {
            let conditionsAndSceneActionsToFollow: IConditionsAndSceneActions[] = [];

            if (!hotspot.conditionsAndSceneActionsActive || !hotspot.conditionsAndSceneActionsInactive) return;
            
            conditionsAndSceneActionsToFollow = hotspot.active ? hotspot.conditionsAndSceneActionsActive : hotspot.conditionsAndSceneActionsInactive;

            for (let e = 0; e < conditionsAndSceneActionsToFollow.length; e++) {
                let currentConditionAndActions = conditionsAndSceneActionsToFollow[e];
                if (!checkCondition(currentConditionAndActions.condition,configuration)) continue;

                for (let i = 0; i < currentConditionAndActions.sceneActions.length; i++) {
                    let currentSceneAction = currentConditionAndActions.sceneActions[i];
                    if (isISceneActionParameterAnimation(currentSceneAction) && hotspot.animationState === "shouldStart") {
                        const mesh = this.scene.getObjectByName(currentSceneAction.objectName);
                        if (mesh) {
                            //@ts-ignore
                            let currentValue = getNestedProperty(mesh, currentSceneAction.parameter);

                            let tempObj = {
                                value: currentValue as number,
                            };

                            //CASE ROTATION
                            if (currentSceneAction.parameter.startsWith("rotation")) {
                                let rotationVector = new Vector3(0, 0, 0);
                                tempObj.value = (tempObj.value * 180) / Math.PI;
                                switch (currentSceneAction.parameter) {
                                    case "rotation.x":
                                        rotationVector = new Vector3(1, 0, 0);
                                        break;
                                    case "rotation.y":
                                        rotationVector = new Vector3(0, 1, 0);
                                        break;
                                    case "rotation.z":
                                        rotationVector = new Vector3(0, 0, 1);
                                        break;
                                }

                                gsap.to(tempObj, {
                                    value: currentSceneAction.value,
                                    duration: currentSceneAction.duration,
                                    ease: CustomEase.create(
                                        "custom",
                                        "M0,0,C0.28,0.02,0.194,1,1,1"
                                    ),
                                    onStart: () => {
                                        useSceneActionHotspotsStore.setState({
                                            sceneActionHotspots:
                                                returnNewSceneActionHotspotsAfterAnimationStart(
                                                    hotspotIndex,
                                                    useSceneActionHotspotsStore.getState()
                                                        .sceneActionHotspots
                                                ),
                                        });
                                    },
                                    onUpdate: () => {
                                        mesh.setRotationFromAxisAngle(
                                            rotationVector,
                                            (tempObj.value * Math.PI) / 180
                                        );
                                        this.requestAnimateIfNotRequested();
                                    },
                                    onComplete: () => {
                                        useSceneActionHotspotsStore.setState({
                                            sceneActionHotspots:
                                                returnNewSceneActionHotspotsAfterAnimationEnd(
                                                    hotspotIndex,
                                                    useSceneActionHotspotsStore.getState()
                                                        .sceneActionHotspots
                                                ),
                                        });
                                    },
                                });
                            }
                            //CASE POSITION
                        }
                    }
                }
            }
        });

        this.requestAnimateIfNotRequested();
    }

    private synchronizeSceneWithState(configuration: IProcessedConfigurationCategory[], currentPackage: EPackage) {
        configuration.forEach((configurationCategory) => {
            if (!configurationCategory.optionGroups) return;

            configurationCategory.optionGroups.forEach(
                (optionGroup, optionGroupIndex) => {
                    optionGroup.options.forEach((option) => {
                        if(option.conditionsOptionVisibility && !checkCondition(option.conditionsOptionVisibility,configuration)) return;
                        if(!option.availableInPackages.includes(currentPackage)) return; 
                        
                        this.synchronizeOptionWithState(option,optionGroup.toggle,configuration);
                        if (option.subOptions) {
                            option.subOptions.forEach((subOption) => {
                                if(subOption.conditionsOptionVisibility && !checkCondition(subOption.conditionsOptionVisibility,configuration)) return;
                                if(!subOption.availableInPackages.includes(currentPackage)) return;
                                
                                this.synchronizeOptionWithState(subOption, option.subOptionsToggle ? option.subOptionsToggle : false,configuration);
                            });
                        }
                    });
                }
            );
        });

        this.requestAnimateIfNotRequested();
    }

    private synchronizeOptionWithState( option: IProcessedConfigurationOption, toggle: boolean, configuration: IProcessedConfigurationCategory[]) {
        let conditionsAndSceneActionsToFollow: IConditionsAndSceneActions[] = [];
        //console.log("#### Handling option: ", option.name);
        if (toggle) {
            if (!option.conditionsAndSceneActionsActive || !option.conditionsAndSceneActionsInactive) return;
            conditionsAndSceneActionsToFollow = option.active ? option.conditionsAndSceneActionsActive : option.conditionsAndSceneActionsInactive;
        }
        else {
            if (!option.active) return;
            if (!option.conditionsAndSceneActions) return;

            conditionsAndSceneActionsToFollow = option.conditionsAndSceneActions;
        }

        for (let e = 0; e < conditionsAndSceneActionsToFollow.length; e++) {
            //console.log("Looping through conditions");
            let currentConditionAndActions = conditionsAndSceneActionsToFollow[e];
            //checkCondition(currentConditionAndActions.condition, configuration);
            if (!checkCondition(currentConditionAndActions.condition, configuration)) continue;

            this.performSceneActions(currentConditionAndActions.sceneActions);
        }
    }

    private performSceneActions(sceneActions: (ISceneActionVisibility | ISceneActionColor | ISceneActionTexture | ISceneActionBackground | ISceneActionMaterial | ISceneActionPosition | ISceneActionParameterAnimation)[]) {
        for (let i = 0; i < sceneActions.length; i++) {
            let currentSceneAction = sceneActions[i];
            if (isISceneActionVisibility(currentSceneAction)) {
                currentSceneAction.visibleMeshNames.forEach((meshName) => {
                    const mesh = this.scene.getObjectByName(meshName);
                    if (mesh !== undefined) {
                        mesh.visible = true;
                    }
                });
                currentSceneAction.invisibleMeshNames.forEach((meshName) => {
                    const mesh = this.scene.getObjectByName(meshName);
                    if (mesh !== undefined) {
                        mesh.visible = false;
                    }
                });
            }
            if (isISceneActionColor(currentSceneAction)) {
                let color: Color = new Color(0, 0, 0);
                if (currentSceneAction.RALCode) {
                    color = new Color(ralToHex(currentSceneAction.RALCode));
                }
                //
                else if (currentSceneAction.RGBValues) {
                    color = new Color(
                        `rgb(${currentSceneAction.RGBValues[0]},${currentSceneAction.RGBValues[1]},${currentSceneAction.RGBValues[2]})`
                    );
                }
                //
                else if (currentSceneAction.RGBValuesNormalized) {
                    color = new Color(
                        currentSceneAction.RGBValuesNormalized[0],
                        currentSceneAction.RGBValuesNormalized[1],
                        currentSceneAction.RGBValuesNormalized[2]
                    );
                }

                currentSceneAction.materialNames.forEach((materialName) => {
                    const currentMaterial = this.materialList.find(
                        (x) => x.name === materialName
                    );
                    if (currentMaterial !== undefined) {
                        currentMaterial.color = color;
                        currentMaterial.needsUpdate = true;
                    }
                });
            }
            if (isISceneActionTexture(currentSceneAction)) {
                const textureFileName = currentSceneAction.textureFileName;
                const repeatU = currentSceneAction.repeatU;
                const repeatV = currentSceneAction.repeatV;
                const textureProperty = currentSceneAction.textureProperty;

                currentSceneAction.materialNames.forEach((materialName) => {
                    const currentMaterial = this.materialList.find(
                        (x) => x.name === materialName
                    );
                    if (currentMaterial !== undefined) {
                        if (this.textures[removeExtension(textureFileName)] !== undefined) {
                            this.textures[removeExtension(textureFileName)].repeat.x =
                                repeatU;
                            this.textures[removeExtension(textureFileName)].repeat.y =
                                repeatV;
                            currentMaterial[textureProperty] =
                                this.textures[removeExtension(textureFileName)];
                            currentMaterial.needsUpdate = true;
                        }
                        else {
                            this.textureLoader.load(textureFileName, (texture) => {
                                this.textures[removeExtension(textureFileName)] = texture;
                                this.textures[removeExtension(textureFileName)].flipY = false;
                                this.textures[removeExtension(textureFileName)].wrapS =
                                    RepeatWrapping;
                                this.textures[removeExtension(textureFileName)].wrapT =
                                    RepeatWrapping;
                                this.textures[removeExtension(textureFileName)].repeat.x =
                                    repeatU;
                                this.textures[removeExtension(textureFileName)].repeat.y =
                                    repeatV;
                                this.textures[removeExtension(textureFileName)].colorSpace =
                                    SRGBColorSpace;
                                currentMaterial[textureProperty] =
                                    this.textures[removeExtension(textureFileName)];
                                currentMaterial.needsUpdate = true;
                                this.requestAnimateIfNotRequested();
                            });
                        }
                    }
                });
            }
            if (isISceneActionBackground(currentSceneAction)) {
                const oldGradientColor1 =
                    this.studioRampMaterial.uniforms.gradientColor1.value.clone();
                const oldGradientColor2 =
                    this.studioRampMaterial.uniforms.gradientColor2.value.clone();
                const newGradientColor1 = new Color(currentSceneAction.gradientColor1);
                const newGradientColor2 = new Color(currentSceneAction.gradientColor2);

                const tempObj = {
                    color1R: oldGradientColor1.r,
                    color1G: oldGradientColor1.g,
                    color1B: oldGradientColor1.b,
                    color2R: oldGradientColor2.r,
                    color2G: oldGradientColor2.g,
                    color2B: oldGradientColor2.b,
                };

                if (this.activeBackgroundTween) {
                    this.activeBackgroundTween.kill();
                }
                this.activeBackgroundTween = gsap.to(tempObj, {
                    color1R: newGradientColor1.r,
                    color1G: newGradientColor1.g,
                    color1B: newGradientColor1.b,
                    color2R: newGradientColor2.r,
                    color2G: newGradientColor2.g,
                    color2B: newGradientColor2.b,
                    duration: 0.8,
                    ease: CustomEase.create("custom", "M0,0,C0.28,0.02,0.194,1,1,1"),
                    onUpdate: () => {
                        this.studioRampMaterial.uniforms.gradientColor1.value = new Color(
                            tempObj.color1R,
                            tempObj.color1G,
                            tempObj.color1B
                        );
                        this.studioRampMaterial.uniforms.gradientColor2.value = new Color(
                            tempObj.color2R,
                            tempObj.color2G,
                            tempObj.color2B
                        );
                        this.requestAnimateIfNotRequested();
                    },
                });

                const gradientColor1RGB = hexToRgb(currentSceneAction.gradientColor1);
                const gradientColor2RGB = hexToRgb(currentSceneAction.gradientColor2);
                if (gradientColor1RGB !== null && gradientColor2RGB !== null) {
                    document.documentElement.style.setProperty(
                        "--color-raw-bg-gradient-1",
                        `${gradientColor1RGB.r},${gradientColor1RGB.g},${gradientColor1RGB.b}`
                    );
                    document.documentElement.style.setProperty(
                        "--color-raw-bg-gradient-2",
                        `${gradientColor2RGB.r},${gradientColor2RGB.g},${gradientColor2RGB.b}`
                    );
                }

                if (!currentSceneAction.fontInverted) {
                    document.documentElement.style.setProperty(
                        "--color-font-primary-dynamic",
                        "rgba(0,0,0,1)"
                    );
                    document.documentElement.style.setProperty(
                        "--color-font-primary-invisible-dynamic",
                        "rgba(0,0,0,0)"
                    );
                    document.documentElement.style.setProperty(
                        "--color-font-primary-inverted-dynamic",
                        "rgba(255,255,255,1)"
                    );
                    document.documentElement.style.setProperty(
                        "--color-font-primary-disabled-dynamic",
                        "rgba(0,0,0,0.3)"
                    );
                    document.documentElement.style.setProperty(
                        "--color-font-primary-soft-dynamic",
                        "rgba(0,0,0,0.5)"
                    );
                }
                else {
                    document.documentElement.style.setProperty(
                        "--color-font-primary-dynamic",
                        "rgba(255,255,255,1)"
                    );
                    document.documentElement.style.setProperty(
                        "--color-font-primary-invisible-dynamic",
                        "rgba(255,255,255,0)"
                    );
                    document.documentElement.style.setProperty(
                        "--color-font-primary-inverted-dynamic",
                        "rgba(0,0,0,1)"
                    );
                    document.documentElement.style.setProperty(
                        "--color-font-primary-disabled-dynamic",
                        "rgba(255,255,255,0.4)"
                    );
                    document.documentElement.style.setProperty(
                        "--color-font-primary-soft-dynamic",
                        "rgba(255,255,255,0.75)"
                    );
                }
            }
            if (isISceneActionMaterial(currentSceneAction)) {
                currentSceneAction.meshNames.forEach((meshName) => {
                    const mesh = this.scene.getObjectByName(meshName);
                    if (mesh !== undefined) {
                        //@ts-ignore
                        const materialToAssign = this.materialList.find((x) => x.name === currentSceneAction.materialName);
                        if (!materialToAssign) return;
                        //@ts-ignore
                        mesh.material = materialToAssign;
                    }
                });
            }
        }
    }

    private returnViewportDependentCameraPosition(cameraPosition: ICameraPositionAllViewports) {
        const isPortrait = this.canvas.offsetWidth < this.canvas.offsetHeight;

        if (isPortrait && cameraPosition.portrait) {
            return cameraPosition.portrait;
        }

        return cameraPosition.landscape;
    }

    private handleStepIndexUpdate(currentStepIndex: number) {
        if (currentStepIndex >= STEPS_BEFORE_CONFIGURATION && currentStepIndex < STEPS_BEFORE_CONFIGURATION + DATA.configurationCategories.length + STEPS_AFTER_CONFIGURATION - 1) {
            const dataIndex = stepIndexToDataIndex(currentStepIndex);
            this.animateCamera((DATA.configurationCategories as IConfigurationCategory[])[dataIndex].cameraPositionData);
        }
        else if (currentStepIndex === 0) {
            this.animateCamera(this.config.startCameraPosition);
        }
        else if (currentStepIndex === 1) {
            this.animateCamera({
                landscape: {
                    "target":{
                        "x": 0.7830406042496586,
                        "y": 0.9673449609574375,
                        "z": 0.01097138921228671
                    },
                    "camera": {
                        "x": 16.09596053720186,
                        "y": 6.210007620513475,
                        "z": 0.07692277554360376
                    }
                }
            });
        }
        else if (currentStepIndex === STEPS_BEFORE_CONFIGURATION + DATA.configurationCategories.length + STEPS_AFTER_CONFIGURATION - 1) {
            this.animateCamera({
                landscape: {
                    target: {
                        x: 0.6038462893790547,
                        y: 0.8506045227572985,
                        z: 0.16077793444216162,
                    },
                    camera: {
                        x: 13.588678220346285,
                        y: 5.302335494669178,
                        z: -9.767100699171657,
                    },
                },
                portrait: {
                    target: {
                        x: 0.7601264799914346,
                        y: 0.871665939623692,
                        z: 0.3946765848983703,
                    },
                    camera: {
                        x: 9.980969581383636,
                        y: 2.977935167334655,
                        z: -3.2686986627739394,
                    },
                },
            });
        }
    }

    private handleCamOffsetUpdate(newOffset: { x: number; y: number }, oldOffset: { x: number; y: number }) {
        let tempObj = {
            offsetX: 0,
            offsetY: 0,
        };

        this.activeCameraTween = gsap.fromTo(
            tempObj,
            {
                offsetX: oldOffset.x,
                offsetY: oldOffset.y,
            },
            {
                offsetX: newOffset.x,
                offsetY: newOffset.y,
                duration: 1.5,
                ease: CustomEase.create("custom", "M0,0,C0.28,0.02,0.194,1,1,1"),
                onUpdate: () => {
                    const calculatedOffsetX =
                        (this.canvas.clientWidth + tempObj.offsetX) / 2 -
                        this.canvas.clientWidth / 2;
                    const calculatedOffsetY =
                        (this.canvas.clientHeight + tempObj.offsetY) / 2 -
                        this.canvas.clientHeight / 2;
                    this.userCamera.setViewOffset(
                        this.canvas.clientWidth,
                        this.canvas.clientHeight,
                        calculatedOffsetX,
                        calculatedOffsetY,
                        this.canvas.clientWidth,
                        this.canvas.clientHeight
                    );
                    this.userCamera.updateProjectionMatrix();

                    this.requestAnimateIfNotRequested();
                },
            }
        );

        this.requestAnimateIfNotRequested();
    }

    /**
     * Logging function. Disabled by setting config.debug to false
     * @param s {string}
     * @private
     */
    private log(s: string): void {
        if (this.config.debug && window.console && window.console.log) {
            window.console.log.apply(window.console, [s]);
        }
    }

    /**
     * Loads GLTF and HDR files
     * @private
     */
    private loadObjects(): void {
        this.log("load");
        this.RGBELoader.load(
            "env.hdr",
            this.handleRGBELoaded,
            this.handleRGBELoadingProgress
        );
        this.RGBELoader.load("studio-ramp-texture.hdr", (texture) => {
            this.studioRampTexture = texture;
            //Using flipY doesn't work here because Firefox ignores it
            this.studioRampTexture.flipY = false;
            //this.studioRampTexture.wrapT = RepeatWrapping;
            //this.studioRampTexture.repeat.y = -1;
            this.studioRampMaterial.uniforms.hdrTexture.value =
                this.studioRampTexture;
            this.studioRampTexture.needsUpdate = true;
            this.studioRampMaterial.needsUpdate = true;
            this.requestAnimateIfNotRequested();
        });
        this.GLTFLoader.load(
            process.env.PUBLIC_URL + "/objects/sterk.glb",
            this.handleGLTFLoaded,
            this.handleGLTFLoadingProgress
        );
    }

    /**
     * Loading progress handler for RGBE-texture loader (env-texture)
     * @param request
     * @private
     */
    private handleRGBELoadingProgress(request: any): void {
        this.handleAnyLoadingProgress({
            type: "rgbe",
            total: request.total,
            loaded: request.loaded,
        });
    }

    /**
     * Loading progress handler for GLTF-loader (main model file)
     * @param request
     * @private
     */
    private handleGLTFLoadingProgress(request: any): void {
        this.handleAnyLoadingProgress({
            type: "gltf",
            total: request.total,
            loaded: request.loaded,
        });
    }

    /**
     * Calculates data from all loading processes and sends data to consumer
     * @effects triggers this.consumerHandleLoadingProgress with relevant data
     * @param payload
     * @private
     */
    private handleAnyLoadingProgress(payload: {
        type: "gltf" | "rgbe";
        total: number;
        loaded: number;
    }) {
        switch (payload.type) {
            case "gltf":
                this.loadingStats.gltfLoaded = payload.loaded;
                //this.loadingStats.gltfTotal = payload.total;
                break;
            case "rgbe":
                this.loadingStats.rgbeLoaded = payload.loaded;
                //this.loadingStats.rgbeTotal = payload.total;
                break;
        }
        //console.log('rgbe: ', this.loadingStats.rgbeLoaded, 'gltf: ', this.loadingStats.gltfLoaded);
        useGlobalStore.setState({
            loadedPercentage: Math.floor(
                ((this.loadingStats.rgbeLoaded + this.loadingStats.gltfLoaded) /
                    (this.loadingStats.rgbeTotal + this.loadingStats.gltfTotal)) *
                100
            ),
        });

        this.consumerHandleLoadingProgress({
            loadedBytes: this.loadingStats.rgbeLoaded + this.loadingStats.gltfLoaded,
            totalBytes: this.loadingStats.rgbeTotal + this.loadingStats.gltfTotal,
            loadedPercentage: Math.floor(
                ((this.loadingStats.rgbeLoaded + this.loadingStats.gltfLoaded) /
                    (this.loadingStats.rgbeTotal + this.loadingStats.gltfTotal)) *
                100
            ),
        });
    }

    /**
     * Handler for RGBELoader "loaded" event
     * @param texture
     * @private
     */
    private handleRGBELoaded(texture: Texture) {
        const gen = new PMREMGenerator(this.renderer);
        this.envTexture = gen.fromEquirectangular(texture).texture;
        this.scene.environment = this.envTexture;
        this.loadingStats.rgbeWasLoaded = true;
        this.handleAnyObjectHasLoaded();
    }

    /**
     * Handler for GLTF-Loader "loaded" event. Traverses scene and connects meshes, materials, animations
     * to class properties
     * @param gltf
     * @private
     */
    private handleGLTFLoaded(gltf: GLTF) {
        this.scene.add(gltf.scene);

        const aspectRatio = this.canvas.clientWidth / this.canvas.clientHeight;
        const zoom = mapRange(
            aspectRatio,
            this.config.containObjectInViewParams.portraitAspectRatio,
            this.config.containObjectInViewParams.portraitZoom,
            this.config.containObjectInViewParams.landscapeAspectRatio,
            this.config.containObjectInViewParams.landscapeZoom
        );

        this.userCamera.aspect = aspectRatio;
        this.userCamera.zoom = zoom;
        this.userCamera.updateProjectionMatrix();

        this.scene.traverse((object) => {
            if (object.type === "Mesh") {
                object.castShadow = true;
                object.receiveShadow = true;
                object.frustumCulled = false;
                //@ts-ignore
                if (object.material) {
                    let materialExists = false;
                    for (let i = 0; i < this.materialList.length; i++) {
                        //@ts-ignore
                        if (this.materialList[i].name === object.material.name) {
                            materialExists = true;
                            break;
                        }
                    }
                    if (!materialExists) {
                        //@ts-ignore
                        object.material.aoMapIntensity = 0.65;
                        //@ts-ignore
                        object.material.envMapIntensity = 0.95;
                        //@ts-ignore
                        this.materialList.push(object.material);
                    }
                }
            }
            if (object.userData && object.userData.isStudioRamp === "true") {
                object.castShadow = false;
                object.receiveShadow = false;

                //@ts-ignore
                object.material = this.studioRampMaterial;
                this.studioRampMaterial.needsUpdate = true;
            }
            //Set main3dObjectPositionOffset if applies
            if (
                object.userData &&
                object.userData.isMain3dObject &&
                object.userData.isMain3dObject === "true"
            ) {
                //Set position from configuration
                object.position.set(-2.5, object.position.y, object.position.z);
            }
            if (object.userData && object.userData.isMaterialHolder === "true") {
                object.visible = false;
            }
            if (object.userData && object.userData.isHotspotFor) {
                //object.visible = false;
                const position = new Vector3();
                position.copy(object.position);

                useSceneActionHotspotsStore
                    .getState()
                    .sceneActionHotspots.forEach((hotspot, hotspotIndex) => {
                    if (hotspot.name === object.userData.isHotspotFor) {
                        this.hotspots[hotspotIndex] = {
                            distanceFromCamera: null,
                            zIndex: null,
                            object3D: object,
                        };
                    }
                });
            }
        });

        //Cam Target Helper
        const camTargetGeometry1 = new BoxGeometry(0.05, 4.0, 0.05);
        const camTargetGeometry2 = new BoxGeometry(4.0, 0.05, 0.05);
        const camTargetGeometry3 = new BoxGeometry(0.05, 0.05, 4.0);
        const camTargetMaterial = new MeshBasicMaterial({
            color: new Color(0.0, 1.0, 0.0),
            name: "camTargetMaterial",
        });
        const camTargetMesh1 = new Mesh(camTargetGeometry1, camTargetMaterial);
        const camTargetMesh2 = new Mesh(camTargetGeometry2, camTargetMaterial);
        const camTargetMesh3 = new Mesh(camTargetGeometry3, camTargetMaterial);
        this.camTargetHelper = new Group();
        this.camTargetHelper.add(camTargetMesh1);
        this.camTargetHelper.add(camTargetMesh2);
        this.camTargetHelper.add(camTargetMesh3);
        this.scene.add(this.camTargetHelper);
        this.camTargetHelper.visible = this.config.showCamTarget;

        //TestCube
        /*
            const geometry = new BoxGeometry(0.2, 0.2, 0.2);
            const material = new MeshBasicMaterial({ color: 0x00ff00 });
            const cube = new Mesh(geometry, material);
            this.scene.add(cube);
            
             */

        this.loadingStats.gltfWasLoaded = true;
        this.handleAnyObjectHasLoaded();
    }

    /**
     * Is triggered on each "loaded"-event by all Loaders and determines whether all loaders are done and
     * tells the consumer
     * @effects triggers this.consumerHandleAfterLoad()
     * @private
     */
    private handleAnyObjectHasLoaded(): void {
        if (this.loadingStats.gltfWasLoaded && this.loadingStats.rgbeWasLoaded) {
            this.consumerHandleAfterLoad();
            this.performSceneActions(DATA.packageSceneActions[useConfigurationStore.getState().basicEquipmentPackage] as TSceneAction[])
            this.synchronizeSceneWithState(useConfigurationStore.getState().configuration, useConfigurationStore.getState().basicEquipmentPackage);
            useGlobalStore.setState({isLoading: false});
            

            //const gui = new GUI();
            const mFoil = this.materialList.find((x) => x.name === "m_foil-hull");
            const mUpholstery = this.materialList.find((x) => x.name === "m_upholstery-all");
            const mGfk = this.materialList.find((x) => x.name === "m_gfk-hull-one");
            const mGfkHull2 = this.materialList.find((x) => x.name === "m_gfk-hull-two");
            const mGfkRc = this.materialList.find((x) => x.name === "m_gfk-rc");
            const mTeak = this.materialList.find((x) => x.name === "m_teak");
            const params = {
                bgColorBottom: "#ffffff",
                bgColorTop: "#ffffff",
            };
            //if (mFoil) gui.add(mFoil.color, 'r', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mFoil) gui.add(mFoil.color, 'g', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mFoil) gui.add(mFoil.color, 'b', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if(mUpholstery) gui.add(mUpholstery, 'roughness', 0, 1).onChange(() => {this.requestAnimateIfNotRequested()});
            //if (mGfk) gui.add(mGfk, 'roughness', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mGfk)gui.add(mGfk.color, 'r', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mGfk)gui.add(mGfk.color, 'g', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mGfk)gui.add(mGfk.color, 'b', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mTeak)gui.add(mTeak, 'roughness', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mTeak)gui.add(mTeak.normalScale, 'x', -20, 20).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mTeak)gui.add(mTeak.normalScale, 'y', -20, 20).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mTeak)gui.add(mTeak.color, 'r', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mTeak)gui.add(mTeak.color, 'g', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mTeak)gui.add(mTeak.color, 'b', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mGfkHull2)gui.add(mGfkHull2, 'roughness', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mGfkHull2)gui.add(mGfkHull2.color, 'r', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mGfkHull2)gui.add(mGfkHull2.color, 'g', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mGfkHull2)gui.add(mGfkHull2.color, 'b', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mGfkHull2)gui.add(mGfkHull2, 'aoMapIntensity', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});

            //if (mGfkRc)gui.add(mGfkRc, 'roughness', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mGfkRc)gui.add(mGfkRc.color, 'r', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mGfkRc)gui.add(mGfkRc.color, 'g', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mGfkRc)gui.add(mGfkRc.color, 'b', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mGfkRc)gui.add(mGfkRc, 'aoMapIntensity', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mGfkRc)gui.add(mGfkRc.emissive, 'r', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mGfkRc)gui.add(mGfkRc.emissive, 'g', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (mGfkRc)gui.add(mGfkRc.emissive, 'b', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});

            //if (this.studioRampMaterial) gui.addColor(params, 'bgColorBottom').onChange(() => { this.studioRampMaterial.uniforms.gradientColor1.value.set(params.bgColorBottom); this.requestAnimateIfNotRequested();});
            //if (this.studioRampMaterial) gui.addColor(params, 'bgColorTop').onChange(() => { this.studioRampMaterial.uniforms.gradientColor2.value.set(params.bgColorTop); this.requestAnimateIfNotRequested();});
            //if(this.studioRampMaterial) gui.add(this.studioRampMaterial.uniforms.toneMappingExposure2, 'value', 0, 3).name('toneMappingExposure').onChange(() => {this.requestAnimateIfNotRequested()});
            //if(this.studioRampMaterial) gui.add(this.studioRampMaterial.uniforms.minY, 'value', -5, 5).step(0.01).name('minY').onChange(() => {this.requestAnimateIfNotRequested()});
            //if(this.studioRampMaterial) gui.add(this.studioRampMaterial.uniforms.maxY, 'value', -5, 5).step(0.01).name('maxY').onChange(() => {this.requestAnimateIfNotRequested()});
            //if(mGfk) gui.add(mGfk, 'aoMapIntensity', 0, 1).onChange(() => {this.requestAnimateIfNotRequested()});
            //if (this.spotlight) gui.add(this.spotlight.color, 'r', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (this.spotlight) gui.add(this.spotlight.color, 'g', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (this.spotlight) gui.add(this.spotlight.color, 'b', 0, 1).onChange(() => {this.requestAnimateIfNotRequested();});
            //if (this.spotlight) gui.add(this.spotlight, 'intensity', 0, 10).onChange(() => {this.requestAnimateIfNotRequested();});

            this.requestAnimateIfNotRequested();
        }
    }

    /**
     * Event handler for a resize event on window
     * @private
     */
    private handleResize(): void {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
            const aspectRatio = this.canvas.clientWidth / this.canvas.clientHeight;
            const zoom = mapRange(
                aspectRatio,
                this.config.containObjectInViewParams.portraitAspectRatio,
                this.config.containObjectInViewParams.portraitZoom,
                this.config.containObjectInViewParams.landscapeAspectRatio,
                this.config.containObjectInViewParams.landscapeZoom
            );

            this.userCamera.aspect = aspectRatio;
            this.userCamera.zoom = zoom;
            this.userCamera.updateProjectionMatrix();

            let width = Math.floor(this.canvas.clientWidth * window.devicePixelRatio);
            let height = Math.floor(this.canvas.clientHeight * window.devicePixelRatio);
            let ratio = height / width;
            if (width > this.config.maxResolution) {
                width = this.config.maxResolution;
                height = width * ratio;
            }
            if (height > this.config.maxResolution) {
                height = this.config.maxResolution;
                width = height / ratio;
            }
            this.renderer.setSize(width, height, false);

            this.consumerHandleResize();
            this.requestAnimateIfNotRequested();
        }, 10);
    }

    /**
     * Calculates canvasSpacePixelPositions for all hotspots
     * @effects updates this.hotspots
     * @private
     */
    private setHotspotPositions(): void {
        this.hotspots.forEach((hotspot, hotspotIndex) => {
            if (hotspot === null) return;

            let currentObject = hotspot.object3D;
            /*
                  We can't use .getWorldPosition with camera
                  See https://discourse.threejs.org/t/how-do-i-grab-the-cameras-world-position-in-webxr/16756
                   */
            let cameraPosition = new Vector3();
            cameraPosition.setFromMatrixPosition(this.userCamera.matrixWorld);

            //Calculate distance from camera=
            hotspot.distanceFromCamera = cameraPosition.distanceTo(
                currentObject.position
            );
        });

        this.hotspots.forEach((hotspot, hotspotIndex) => {
            if (hotspot === null) return;

            let currentObject = hotspot.object3D;
            let objectWorldPosition = new Vector3();
            let projectedNDCObjectMiddle = new Vector3(); //NDC = normalized device coordinates
            let projectedVCObjectMiddle = new Vector2(); //VC = viewport coordinates
            currentObject.getWorldPosition(objectWorldPosition);
            projectedNDCObjectMiddle.copy(objectWorldPosition);

            //Project position to get coordinates in normalized space
            projectedNDCObjectMiddle.project(this.userCamera);
            projectedVCObjectMiddle.x = Math.round(
                (0.5 + projectedNDCObjectMiddle.x / 2) * this.canvas.clientWidth
            );
            projectedVCObjectMiddle.y = Math.round(
                (0.5 - projectedNDCObjectMiddle.y / 2) * this.canvas.clientHeight
            );

            let x = projectedVCObjectMiddle.x;
            let y = projectedVCObjectMiddle.y;

            let zIndex = 0;
            this.hotspots.forEach((hotspotToCompare, hotspotToCompareIndex) => {
                if (hotspotToCompare === null) return;
                if (hotspotToCompare.distanceFromCamera === null) return;
                if (hotspot.distanceFromCamera === null) return;
                if (hotspotToCompare.distanceFromCamera < hotspot.distanceFromCamera)
                    return;

                zIndex++;
            });

            this.hotspotRefs[hotspotIndex].style.transform = `translateX(${
                x + "px"
            }) translateY(${y + "px"}) scale(${1})`;
            this.hotspotRefs[hotspotIndex].style.zIndex = zIndex;
        });
    }

    private requestAnimateIfNotRequested() {
        if (!this.animateRequested) {
            this.animateRequested = true;
            cancelAnimationFrame(this.raf);
            this.raf = window.requestAnimationFrame(this.animate);
        }
    }

    /**
     * Main animation loop.
     * @private
     */
    private animate(): void {
        this.animateRequested = false;
        const delta = this.clock.getDelta();

        if (this.controls.enabled) {
            this.controls.update();
        }

        this.consumerHandleFrameRender();
        this.renderer.render(this.scene, this.userCamera);
        this.setHotspotPositions();
    }
}
