import React, { useEffect, useRef } from 'react';
import './ThreeDCanvas.scss';
import { ThreeD } from './ThreeD';
import { useGlobalStore, useThreeDReference } from '../../store/store';
import NavigationInfo from './NavigationInfo/NavigationInfo';
import Hotspots from '../Hotspots/Hotspots';

interface IThreeDCanvasProps {}

function ThreeDCanvas(props: IThreeDCanvasProps) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const isLoading = useGlobalStore(state => state.isLoading);
    const hotspotRefs = useRef<Array<HTMLLIElement>>([]);

    useEffect(() => {
        if (canvasRef.current !== null) {
            useThreeDReference.setState({ threeD: new ThreeD({ canvas: canvasRef.current, hotspotRefs: hotspotRefs.current }) });
            const ThreeDInstance = useThreeDReference.getState().threeD;

            if (ThreeDInstance === null) return;
            ThreeDInstance.init();
        }
    }, []);

    return (
        <div className={'three-d-canvas'}>
            <canvas ref={canvasRef} className={`three-d-canvas ${isLoading ? 'three-d-canvas--invisible' : ''}`}></canvas>
            <NavigationInfo />
            <Hotspots ref={hotspotRefs} />
        </div>
    );
}

export default ThreeDCanvas;
