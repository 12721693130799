import React from 'react';
import './ThumbnailWithText.scss';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { ImCheckmark } from 'react-icons/im';

interface IThumbnailWithTextProps {
    titleHtml?: string;
    descriptionHtml?: string;
    imageUrl?: string;
    active?: boolean;
    onClick?: () => void;
    toggle?: boolean;
}
function ThumbnailWithText(props: IThumbnailWithTextProps) {
    return (
        <button className={`thumbnail-with-text ${props.active ? 'thumbnail-with-text--active' : ''}`}>
            <div
                onClick={props.onClick}
                className={`thumbnail-with-text__inner ${
                    props.descriptionHtml ? 'thumbnail-with-text__inner--has-description' : 'thumbnail-with-text__inner--no-description'
                }`}
            >
                <div className={'thumbnail-with-text__image-container'}>
                    <img src={process.env.PUBLIC_URL + props.imageUrl} alt={'placeholder'} />
                </div>
                <div className={`thumbnail-with-text__text-container`}>
                    {props.titleHtml && (
                        <h2 className={'thumbnail-with-text__title'} dangerouslySetInnerHTML={{ __html: props.titleHtml }} />
                    )}
                    {props.descriptionHtml && (
                        <p className={'thumbnail-with-text__description'} dangerouslySetInnerHTML={{ __html: props.descriptionHtml }} />
                    )}
                </div>
                {props.toggle && (
                    <div className={`thumbnail-with-text__icon-container`}>
                        <div className={`thumbnail-with-text__icon`}>{props.active && <ImCheckmark />}</div>
                    </div>
                )}
            </div>
        </button>
    );
}
export default ThumbnailWithText;
