import React from 'react';
import './SubOptionTextOnly.scss';
import { ImCheckmark } from 'react-icons/im';
import {ECurrencies, IProcessedConfigurationOption} from '../../types/types';
import { useConfigurationStore, useGlobalStore, useThreeDReference } from '../../store/store';
import { returnConfigurationAfterOptionClick, returnConfigurationWithUpdatedPrices, stepIndexToDataIndex, formatPrice } from '../../utilities/utilities';

interface ISubOptionTextOnly {
    toggle?: boolean;
    subOption: IProcessedConfigurationOption;
    optionGroupIndex: number;
    optionIndex: number;
    subOptionIndex: number;
}
function SubOptionTextOnly(props: ISubOptionTextOnly) {
    const {basicEquipmentPackage, configuration} = useConfigurationStore(state => state);
    const currentCurrency = useGlobalStore(state => state.currentCurrency);
    const { currentStepIndex, currentLanguage } = useGlobalStore(state => ({
        currentStepIndex: state.currentStepIndex,
        currentLanguage: state.currentLanguage,
    }));

    const dataIndex = stepIndexToDataIndex(currentStepIndex);
    let formattedPrice = "";
    if(!props.subOption.active) {
        formattedPrice = formatPrice(props.subOption.displayedPrice, true, true);
    }

    return (
        <button className={`sub-option-text-only ${props.subOption.descriptionHtml ? 'sub-option-text-only--has-description' : ''} ${props.toggle ? 'sub-option-text-only--toggle' : ''} ${props.subOption.active ? 'sub-option-text-only--active' : ''}`}>
            <div
                onClick={() => {
                    useConfigurationStore.setState({
                        configuration: returnConfigurationWithUpdatedPrices(returnConfigurationAfterOptionClick(dataIndex, [props.optionGroupIndex, props.optionIndex, props.subOptionIndex], configuration, {toggle: props.toggle, deactivateSiblings: !props.toggle}), basicEquipmentPackage, currentCurrency),
                    });
                    if (props.subOption.cameraPositionData) {
                        useThreeDReference.getState().threeD?.animateCamera(props.subOption.cameraPositionData);
                    }
                }}
                className={`sub-option-text-only__inner`}
            >
                <div className={`sub-option-text-only__text-container`}>
                    {props.subOption.displayName && <h2 className={'sub-option-text-only__title'} dangerouslySetInnerHTML={{ __html: `${props.subOption.displayName[currentLanguage]} ${formattedPrice ? "<span>" + formattedPrice + "</span>" : ""}`}} />}
                    {props.subOption.descriptionHtml && <p className={'sub-option-text-only__description'} dangerouslySetInnerHTML={{ __html: props.subOption.descriptionHtml[currentLanguage] }} />}
                </div>
                {props.toggle && (
                    <div className={`sub-option-text-only__icon-container`}>
                        <div className={`sub-option-text-only__icon`}>{props.subOption.active && <ImCheckmark />}</div>
                    </div>
                )}
            </div>
        </button>
    );
}
export default SubOptionTextOnly;
