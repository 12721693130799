import React, { useRef, useState } from 'react';
import './LightboxContentSimple.scss';
import { QRCodeCanvas as ReactQrCode } from 'qrcode.react';
import DATA from '../../data/data.json';
import { useGlobalStore } from '../../store/store';

interface ILightboxContentSimpleProps {}

function LightboxContentSimple(props: ILightboxContentSimpleProps) {
    const currentLanguage = useGlobalStore(state => state.currentLanguage);

    const inputRef = useRef<HTMLInputElement>(null);
    const [showCopyMessage, setShowCopyMessage] = useState(false);

    const handleCopy = () => {
        if (!inputRef.current) {
            return;
        }

        inputRef.current.select();
        document.execCommand('copy');
        setShowCopyMessage(true);
    };

    return (
        <div className={'lightbox-content-simple'}>
            <input readOnly ref={inputRef} className={'lightbox-content-simple__input'} type='text' value={window.location.href} />
            <div className={'lightbox-content-simple__save'}>
                <div className={'lightbox-content-simple__text-block'} dangerouslySetInnerHTML={{ __html: DATA.locale.saveConfigurationHtml[currentLanguage] }} />
                <div className={'lightbox-content-simple__other-block'}>
                    <button className={'btn'} onClick={handleCopy}>
                        {showCopyMessage ? DATA.locale.copyingDone[currentLanguage] : DATA.locale.copyUrlToClipboard[currentLanguage]}
                    </button>
                </div>
            </div>
            <div className={'lightbox-content-simple__qr'}>
                <div className={'lightbox-content-simple__text-block'} dangerouslySetInnerHTML={{ __html: DATA.locale.openOnDifferentDeviceHtml[currentLanguage] }} />
                <div className={'lightbox-content-simple__other-block'}>
                    <div className={'lightbox-content-simple__qr-code'}>
                        <ReactQrCode value={window.location.href} size={512} style={{ width: '100%', height: '' }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LightboxContentSimple;
