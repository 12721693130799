import { useGlobalStore } from '../../store/store';
import './LanguageMenu.scss';
import {ELanguage} from "../../types/types";

interface ILanguageMenuProps {}

function LanguageMenu() {
    const currentLanguage = useGlobalStore(state => state.currentLanguage);
    return (
        <ul className={'language-menu'}>
            <li
                className={`language-menu__item ${currentLanguage === ELanguage.DE ? 'language-menu__item--active' : ''}`}
                onClick={() => {
                    useGlobalStore.setState({ currentLanguage: ELanguage.DE });
                }}
            >
                DE
            </li>
            <li className={'language-menu__separator'}>|</li>
            <li
                className={`language-menu__item ${currentLanguage === ELanguage.EN ? 'language-menu__item--active' : ''}`}
                onClick={() => {
                    useGlobalStore.setState({ currentLanguage: ELanguage.EN });
                }}
            >
                EN
            </li>
        </ul>
    );
}

export default LanguageMenu;
