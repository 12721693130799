import {useGlobalStore} from "../store/store";
import {useEffect} from "react";

export function useRightWindowCamOffset(windowRef: React.RefObject<HTMLDivElement>, viewportWidth: number, viewportHeight: number) {
    useEffect(() => {
        if (!windowRef.current) return;

        if (viewportHeight > viewportWidth) {
            useGlobalStore.setState({camOffset: {x: 0, y: windowRef.current?.offsetHeight || 0}});
        }
        else {
            useGlobalStore.setState({camOffset: {x: windowRef.current?.offsetWidth || 0, y: 0}});
        }
    }, [viewportWidth, viewportHeight, windowRef]);
}