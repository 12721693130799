import React from 'react';
import './Logo.scss';

interface ILogoProps {}

function Logo(props: ILogoProps) {
    return (
        <a className={'logo'} href={'https://sterkyachts.com/sterk-english/'}>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 311.4885 43.8766'>
                <polygon
                    className='cls-1'
                    points='296.1216 41.1225 268.6446 21.3915 265.1406 41.1225 258.5026 41.1225 265.7555 .0005 272.4556 .0005 269.2596 18.0105 300.9166 .0005 311.4885 .0005 274.9146 19.8545 306.0176 41.1225 296.1216 41.1225'
                />
                <path
                    className='cls-1'
                    d='m117.5509,0h-47.76C56.8209,0,51.1039,5.2861,51.1039,13.6461c0,5.962,4.364,9.282,13.523,9.282h16.597c7.008,0,11.249.553,11.249,4.979,0,4.917-3.504,7.622-10.696,7.622h-38.182c-1.831,2.231-4.06,4.139-6.538,5.593h43.245c13.154,0,18.625-5.286,18.625-13.953,0-6.086-4.549-9.159-13.4-9.159h-16.658c-7.069,0-11.249-.553-11.249-4.795,0-4.917,3.565-7.56,10.696-7.56h56.183l-6.27,35.467h6.7l6.27-35.467h19.179L155.7248,0h-38.174Z'
                />
                <path
                    className='cls-1'
                    d='m256.1659,11.679c0-8.483-8.052-11.679-16.781-11.679h-79.972l-7.253,41.122h39.586l5.348-5.593h-37.251l2.213-12.601h31.226l4.673-4.918h-35.038l2.212-12.355h70.875c7.745,0,13.155,1.66,13.155,7.131,0,5.722-7.193,5.224-15.553,5.224h-29.443l-3.934,23.112h6.638l3.455-18.194h18.933l15.908,18.194h9.036l-16.744-18.194c14.144,0,18.711-2.766,18.711-11.249'
                />
                <path
                    className='cls-2'
                    d='m22.4037,25.4029c2.264,0,3.412-.786,3.412-2.337,0-1.095-.808-1.411-3.606-1.411h-5.806c-4.184,0-5.063-1.947-5.063-3.579,0-3.291,2.44-5.104,6.869-5.104h14.933l.902-5.342H10.4727l-3.003,17.773h14.934Z'
                />
                <path
                    className='cls-2'
                    d='m17.6937,15.6109c-2.264,0-3.412.779-3.412,2.315,0,1.033.842,1.348,3.606,1.348h5.828c4.148,0,5.019,1.922,5.019,3.534,0,3.459-2.303,5.213-6.847,5.213H7.2846c.086,2.11.777,4.013,2.039,5.508,1.681,1.988,4.239,3.083,7.201,3.083,6.961,0,13.585-5.689,14.766-12.681l1.406-8.32h-15.003Z'
                />
                <path
                    className='cls-1'
                    d='m16.5257,43.8766c-5.138,0-9.666-2.009-12.75-5.659C.6377,34.5046-.6053,29.5876.2766,24.3706L4.3317.3646h38.309l-4.186,24.776c-1.746,10.332-11.583,18.736-21.929,18.736M8.2397,4.9876l-3.405,20.153c-.651,3.853.227,7.437,2.472,10.093,2.191,2.592,5.465,4.02,9.219,4.02,8.191,0,15.983-6.677,17.37-14.883l3.275-19.383H8.2397Z'
                />
            </svg>
        </a>
    );
}

export default Logo;
