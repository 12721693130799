import React, { useEffect } from 'react';
import './Intro.scss';
import { useGlobalStore } from '../../store/store';
import { motion } from 'framer-motion';
import DATA from '../../data/data.json';
import { FRAMER_MOTION_VARIANT_CONTAINER, FRAMER_MOTION_VARIANT_FROM_BOTTOM } from '../../utilities/framerMotionVariants';

interface IIntroProps {}

function Intro(props: IIntroProps) {
    const introWindowRef = React.useRef<HTMLDivElement>(null);
    const currentLanguage = useGlobalStore(state => state.currentLanguage);

    useEffect(() => {
        const newCamOffset = {
            x: 0,
            y: introWindowRef.current?.offsetHeight || 0,
        };
        useGlobalStore.setState({ camOffset: newCamOffset });
    }, [introWindowRef.current?.offsetHeight]);

    return (
        <motion.div variants={FRAMER_MOTION_VARIANT_CONTAINER} initial={'initial'} animate={'animate'} exit={'exit'} className={'intro'}>
            <div ref={introWindowRef} className={'intro__inner'}>
                <motion.div variants={FRAMER_MOTION_VARIANT_FROM_BOTTOM} className={'intro__title'}>
                    <h1>{DATA.locale.hero.headlineHtml[currentLanguage]}</h1>
                </motion.div>
                <motion.div variants={FRAMER_MOTION_VARIANT_FROM_BOTTOM} className={'intro__content'}>
                    <div className={'intro__text'} dangerouslySetInnerHTML={{ __html: DATA.locale.hero.copyHtml[currentLanguage] }}></div>
                    <div className={'intro__button-content'}>
                        <button
                            className={'btn'}
                            onClick={() => {
                                useGlobalStore.setState(state => ({ currentStepIndex: state.currentStepIndex + 1 }));
                            }}
                        >
                            {DATA.locale.continue[currentLanguage]}
                        </button>
                    </div>
                </motion.div>
            </div>
        </motion.div>
    );
}

export default Intro;
