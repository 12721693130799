import React from 'react';
import { IConfigurationOption, IProcessedConfigurationCategory, IProcessedConfigurationOption } from '../../types/types';
import './SubOptionColorSwatch.scss';
import { useConfigurationStore, useGlobalStore, useThreeDReference } from '../../store/store';
import {returnConfigurationAfterOptionClick, returnConfigurationWithUpdatedPrices, stepIndexToDataIndex} from '../../utilities/utilities';

interface ISubOptionColorSwatchProps {
    subOption: IProcessedConfigurationOption;
    optionGroupIndex: number;
    optionIndex: number;
    subOptionIndex: number;
}
function SubOptionColorSwatch(props: ISubOptionColorSwatchProps) {
    const {basicEquipmentPackage, configuration} = useConfigurationStore(state => state);
    const { currentStepIndex, currentLanguage, currentCurrency } = useGlobalStore(state => ({
        currentStepIndex: state.currentStepIndex,
        currentLanguage: state.currentLanguage,
        currentCurrency: state.currentCurrency
    }));

    const dataIndex = stepIndexToDataIndex(currentStepIndex);
    return (
        <li
            className={`color-swatch`}
            onClick={() => {
                useConfigurationStore.setState({
                    configuration: returnConfigurationWithUpdatedPrices(returnConfigurationAfterOptionClick(dataIndex, [props.optionGroupIndex, props.optionIndex, props.subOptionIndex], configuration, {toggle: false,deactivateSiblings: true,}), basicEquipmentPackage, currentCurrency)
                });
                if (props.subOption.cameraPositionData) {
                    useThreeDReference.getState().threeD?.animateCamera(props.subOption.cameraPositionData);
                }
            }}
        >
            <div className={`color-swatch__inner`}>
                <div className={`color-swatch__innermost ${props.subOption.active ? 'color-swatch__innermost--active' : ''}`} style={{ backgroundColor: props.subOption.thumbnailColor ? props.subOption.thumbnailColor : '#ffffff' }}></div>
            </div>
        </li>
    );
}

export default SubOptionColorSwatch;
