import { TLanguageString } from './types';

/******************************************************************
 * FormField Interfaces & Types
 *****************************************************************/

export type TFormFieldType = 'text' | 'textarea' | 'email' | 'checkbox' | 'select' | 'radioGroup';
export type TFormFieldTypeGeneric = 'text' | 'textarea' | 'email';

export interface IFormFieldGeneric {
    type: TFormFieldTypeGeneric;
    name: string;
    id: string;
    label: TLanguageString;
    disabled: boolean;
    required: boolean;
    error?: string;
    value?: string;
}

export interface IFormFieldGenericProcessed extends IFormFieldGeneric {
    value: string;
}

export interface IFormFieldCheckbox {
    type: 'checkbox';
    name: string;
    id: string;
    label: TLanguageString;
    disabled: false;
    required: false;
    checked?: boolean;
}

export interface IFormFieldCheckboxProcessed extends IFormFieldCheckbox {
    checked: boolean;
}

export interface IFormFieldRadioGroupChoice {
    id: string;
    label: TLanguageString;
    value: string;
    disabled: boolean;
    checked?: boolean;
}

export interface IFormFieldRadioGroupChoiceProcessed extends IFormFieldRadioGroupChoice {
    checked: boolean;
}

export interface IFormFieldRadioGroup {
    type: 'radioGroup';
    name: string;
    id: string;
    label: TLanguageString;
    choices: IFormFieldRadioGroupChoice[];
    required: boolean;
}

export interface IFormFieldRadioGroupProcessed extends IFormFieldRadioGroup {
    choices: IFormFieldRadioGroupChoiceProcessed[];
}

export interface IFormFieldSelectChoice {
    value: string;
    label: TLanguageString;
    selected?: boolean;
}

export interface IFormFieldSelectChoiceProcessed extends IFormFieldSelectChoice {
    selected: boolean;
}

export interface IFormFieldSelect {
    type: 'select';
    name: string;
    id: string;
    label: TLanguageString;
    choices: IFormFieldSelectChoice[];
    disabled: boolean;
    required: boolean;
}

export interface IFormFieldSelectProcessed extends IFormFieldSelect {
    choices: IFormFieldSelectChoiceProcessed[];
}

/******************************************************************
 * Typeguards
 *****************************************************************/

export function isFormFieldGeneric(formField: IFormFieldGeneric | IFormFieldRadioGroup | IFormFieldSelect | IFormFieldCheckbox): formField is IFormFieldGeneric {
    return formField.type === 'text' || formField.type === 'textarea' || formField.type === 'email';
}

export function isFormFieldRadioGroup(formField: IFormFieldGeneric | IFormFieldRadioGroup | IFormFieldSelect | IFormFieldCheckbox): formField is IFormFieldRadioGroup {
    return formField.type === 'radioGroup';
}

export function isFormFieldSelect(formField: IFormFieldGeneric | IFormFieldRadioGroup | IFormFieldSelect | IFormFieldCheckbox): formField is IFormFieldSelect {
    return formField.type === 'select';
}

export function isFormFieldCheckbox(formField: IFormFieldGeneric | IFormFieldRadioGroup | IFormFieldSelect | IFormFieldCheckbox): formField is IFormFieldCheckbox {
    return formField.type === 'checkbox';
}

export function isLanguageString(languageString: TLanguageString | string): languageString is TLanguageString {
    return typeof languageString === 'object';
}
