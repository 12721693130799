import React from 'react';
import { useGlobalStore } from '../../store/store';
import './LoadingIndicator.scss';
import { clamp, mapRange } from '../../utilities/utilities';

interface ILoadingIndicatorProps {}

function LoadingIndicator(props: ILoadingIndicatorProps) {
    const loadedPercentage = useGlobalStore(state => state.loadedPercentage);

    return (
        <div className={'loading-indicator'}>
            <div className={'loading-indicator__bar'} style={{ width: mapRange(loadedPercentage, 0, 10, 100, 100) + '%' }}></div>
            <div className={'loading-indicator__text'}>Loading {clamp(loadedPercentage, 0, 100)}</div>
        </div>
    );
}

export default LoadingIndicator;
